import React from 'react';
import { SimpleForm, Create, TextInput } from 'react-admin';

const BusinessTypeCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
    </SimpleForm>
  </Create>
);

export default BusinessTypeCreate;