import React from 'react';
import { List, Datagrid, TextField, BooleanField } from 'react-admin';

const SectorList = props => (
  <React.Fragment>
    <List
      {...props}
      sort={{ field: 'name', order: 'ASC' }}
      bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <TextField source="color" />
        <TextField source="image" />
        <TextField source="colorGradient" sortable={false} />
        <BooleanField source="allowMultipleServices" sortable={false} />
        <BooleanField source="allowMultipleCategories" sortable={false} />
      </Datagrid>
    </List>
  </React.Fragment>
);

export default SectorList;