import React from 'react';
import {
  NumberInput,
  Toolbar,
  SaveButton,
  BooleanInput,
  FormTab,
  Edit,
  TextInput,
  TabbedForm,
} from 'react-admin';
import { required } from 'ra-core';
import { NameTitle } from '../../utils/Title.component';

import AvailableFeatureList from '../availableFeature/AvailableFeatureList';

const SaveToolbar = props => (
  <Toolbar {...props} >
    <SaveButton redirect="edit" />
  </Toolbar>
);

const FeatureItemEdit = props => (
  <Edit {...props} title={<NameTitle model="Feature Item" record={props} />}>
    <TabbedForm toolbar={<SaveToolbar />}>
      <FormTab label="Feature Item" redirect="list">
        <TextInput source="name" validate={required()} />
        <NumberInput source="precedence" />
        <BooleanInput source="firstBooking" />
      </FormTab>
      <FormTab label="Available Features">
        <AvailableFeatureList />
      </FormTab>
    </TabbedForm>
  </Edit>
);

export default FeatureItemEdit;