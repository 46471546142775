import React, { useState, useEffect } from 'react';
import dataProvider from '../../../providers/dataProvider';
import { Loading, FormTab } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import { TextField, Select, MenuItem, InputLabel } from '@material-ui/core';
const Charge = (props) => {
  const [loading, setLoading] = useState(false);
  const [availablePlans, setAvailablePlans] = useState();
  const [selectedPlan, setSelectedPlan] = useState(0);
  const [isCnpj, setIsCnpj] = useState(false);
  const [docNumber, setDocNumber] = useState('');
  const [email, setEmail] = useState('');
  const [pixQrCode, setPixQrCode] = useState('');

  const getPlans = async () => {
    try {
      const params = { id: props.id };
      const { data } = await dataProvider('GET_ONE', 'subscriptionPlan', params)
      setAvailablePlans(data);
    } catch (err) {
      alert('Houve um erro, tente novamente')
    }
  }

  useEffect(() => {
    getPlans();
  }, [])

  const formatedData = {
    payment_subscription_type: "pix",
    recipient_id: props.record.id,
    plan_id: availablePlans && availablePlans[selectedPlan].id,
    doc: docNumber,
    doc_type: isCnpj ? "cnpj" : "cpf",
    email: email,
    name: props.record.name,
    phone: props.record.phone,
    neighborhood: "Jardim Botânico",
    city: "Ribeirão Preto",
    state: "SP",
    street: "Av. Professor João Fiusa",
    street_number: "1901",
    zip_code: "14024250",
  }

  // const copyToClipBoard = (code = pixQrCode) => {
  //   const textField = document.createElement('textarea');
  //   textField.innerText = code;
  //   document.body.appendChild(textField);
  //   textField.select();
  //   document.execCommand('copy');
  //   textField.remove();
  //   alert('Código PIX copiado para a área de transferencia!')
  // };

  const getQrCode = async () => {
    if (email && docNumber && availablePlans[selectedPlan]) {
      try {
        setLoading(true)
        const { data } = await dataProvider(`GET_ONE`, `getQrCode`, formatedData);
        setPixQrCode(data.pixQrCode)
        // copyToClipBoard(data.pixQrCode);

        const paymentData = await dataProvider(`GET_ONE`, `businessPlan`, props.record);
        if ((paymentData && paymentData.data[0] && paymentData.data[0].status === 'testing')
        || (paymentData && paymentData.data[0] && paymentData.data[0].status === 'inactive')) {
          await dataProvider(`GET_ONE`, `businessPlanSign`, paymentData.data[0]);
        }
        setLoading(false)

      } catch (err) {
        setLoading(false)
        alert(`Erro: ${JSON.stringify(err.body.message)}`)
      }
    } else {
      alert('Preencha todos os dados para Prosseguir!')
    }
  }

  const tabStyle = { minWidth: 50, marginLeft: -10, marginRight: -10 };

  return (

    <div style={{ marginTop: 15 }}>
      <FormTab label="PixCode" style={tabStyle} >
        <Grid container >
          <Grid item xs={3} sm={1} style={{ alignSelf: 'end' }}>
            <label>{isCnpj ? 'CNPJ' : 'CPF'}</label>
            <Switch selected={isCnpj} onChange={() => setIsCnpj(!isCnpj)} label={isCnpj ? 'CNPJ' : 'CPF'} />
          </Grid>
          <Grid item xs={3} sm={3}>
            <TextField
              value={docNumber}
              onChange={event => {
                setDocNumber(event.target.value);
              }}
              label={isCnpj ? 'CNPJ' : 'CPF'}
            />
          </Grid>
          <Grid item xs={3} sm={3}>
            <TextField
              value={email}
              onChange={event => {
                setEmail(event.target.value);
              }}
              label="Email" />
          </Grid>
        </Grid>
        <Grid container >
          <InputLabel style={{ margin: '10px', alignSelf: 'end' }} id="Plano">Plano</InputLabel>
          <Grid item xs={3} sm={3}>
            <Select
              style={{ margin: '10px', width: '200px' }}
              labelId="Plano"
              id="Plano"
              value={selectedPlan}
              label="Plano"
              onChange={event => { setSelectedPlan(event.target.value) }}
            >
              {availablePlans && availablePlans.map((plan, index) => {
                return (<MenuItem value={index}>{plan.title}</MenuItem>)
              })}
            </Select>
          </Grid>
        </Grid>
        <Grid container />
      </FormTab>
      {!pixQrCode && !loading &&
        <button
          onClick={() => getQrCode()}
          disabled={loading}
          style={{
            padding: 5,
            marginTop: 30,
            borderRadius: 5,
            backgroundColor: '#3689ea',
            color: '#ffffff',
            fontWeight: 'bold',
            minWidth: 250
          }}>
          Salvar
        </button>}
      {pixQrCode && !loading &&
        <>
          <div style={{
            padding: 5,
            margin: 5,
            backgroundColor: '#2196f3',
            color: '#ffffff',
            fontWeight: 'bold',
          }}>{`Código PIX: `}
          </div>

          <div style={{
            padding: 5,
            margin: 5,
            backgroundColor: '#2196f3',
            color: '#ffffff',
            fontWeight: 'bold',
          }}>{`${pixQrCode}`}
          </div>
        </>
      }
      {(loading &&
        <Loading />
      )}
    </div>
  )
};

export default Charge;