import React, { useState, useEffect } from 'react';
import { Tooltip } from '@material-ui/core';
import dataProvider from '../../../providers/dataProvider';
import styles from './Styles';
import { Loading, Button } from 'react-admin';

const Rating = (props) => {
  const [nextData, setNextData] = useState([]);
  const [dataByBusiness, setDataByBusiness] = useState([]);
  const [dataRatings, setDataRatings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [firstOpen, setFirstOpen] = useState(true);
  const [dataToShow, setDataToShow] = useState([]);
  const [page, setPage] = useState(1);
  const [showAllBusiness, setShowAllBusiness] = useState(false);
  const perPage = 15;
  
  useEffect(() => {
    async function fetchDataRating() {
      if (firstOpen && props.show) {
        setFirstOpen(false);
        //by business
        try {
          setLoading(true);
          const params = { page: 1, per_page: 100, businessId: props.record.id  };
          const { data } = await dataProvider('GET_ONE', 'rating', params);
          setDataByBusiness(data);
          setDataToShow(data);
          setLoading(false);
        } catch (err) {
          console.log('Erro get data.', err);
          setLoading(false);
        }
        // all business
        const dataPageOne = await getRatingData(0);
        setDataRatings(dataPageOne);

        const dataPageTwo = await getRatingData(1);
        setNextData(dataPageTwo);
      };
    };
    fetchDataRating();
    
  }, [props.show]);

  useEffect(() => {
    if (showAllBusiness) {
      setDataToShow(dataRatings);
    } else {
      setDataToShow(dataByBusiness);
    }
  }, [showAllBusiness]);

  
  const pagination = () => {
    return (
      <>
        <div style={styles.pageRow}>
          <div style={{size: '25%'}}>
            {page > 1 &&
              <Button
                style={styles.button}
                label="Anterior"
                onClick={() => { changePage(-1) }}
                enab
              />
            }
          </div>
        <div style={{size: '10%'}}>
          {page}
        </div>
          <div style={{size: '25%'}}>
            {nextData && nextData.length &&
              <Button
                style={styles.button}
                label="Próximo"
                onClick={() => { changePage(1)}}
              />
            }
          </div>
        </div>
      </>
    );
  };

  const linha = (rate, index) => {
    return (
      <>
        {!index &&
          <tr key={index + 'header'} style={styles.headerRow}>
            <th style={styles.header}> User </th>
            <th style={styles.headerGrade}> Avaliação </th>
            <th style={styles.headerComment}> Comentário </th>
          </tr>
        }
        <Tooltip title={`${rate.comment}`} placement='bottom'>
          <tr key={index} style={styles.row}>
            <td style={styles.line}> {rate.user.name}</td>
            <td style={styles.lineGrade}>{rate.grade}</td>
            <td style={styles.lineComment} onClick={() => alert(`Nota: ${rate.grade} \n Comentário: ${rate.comment}`)}>{rate.comment}</td>
          </tr>
        </Tooltip>
      </>
    )
  };

  const changePage = async (next) => {
    if (next === 1) {
      //change page
      setDataRatings(nextData);
      setDataToShow(nextData);

      //get next page
      if (nextData.length === perPage) {
        const fetchNextData = await getRatingData(next + 1);
        setNextData(fetchNextData);
      } else {
        setNextData(null);
      }
      
    } else {
      //voltar 1 pagina
      setNextData(dataRatings);
      setLoading(true);
      const fetchData = await getRatingData(next);
      setDataRatings(fetchData);
      setDataToShow(fetchData);
      setLoading(false);
    }
    setPage(page + next);
  }
  
  const getRatingData = async (next = 0) => {
    try {
      const params = { page: page + next, per_page: perPage };
      const { data } = await dataProvider('GET_ONE', 'rating', params);
      return data;
    } catch (err) {
      console.log('Erro get data.', err);
    }
  };

  return (
    <>
      {!props.show &&
        <Button
          style={styles.button}
          label="Selecione uma Guia"
        />
      }
      {props.show &&
        <>
          <div style={{ justifyContent: 'space-between', width: '95%', marginLeft: 80 , marginTop: 15}}>
            <Button
              label='Avaliações do Business'
            onClick={() => { setShowAllBusiness(false) }}
              style={styles.button}
            />
            <Button
              label='Todas as Avaliações'
              onClick={() => { setShowAllBusiness(true) }}
              style={styles.button}
            />
          </div>
            <div style={styles.Container}>
            <div>
              {loading && <Loading />}
              {!loading && (!dataToShow || dataToShow.length === 0) &&
                <>          
                  <div style={styles.TextContainer}>
                    <div style={styles.WithoutTransactionsMessage}>
                      Sem avaliações para exibir
                    </div>
                  </div>
                </>  
              }
            </div>
            {dataToShow && 
              <table>
                {dataToShow.map((rate, index) => {
                  return (linha(rate, index));
                })}
              </table>
            }
        </div>
        {showAllBusiness &&
        <div>
          {pagination()}
        </div>
        }
        </>
      }
    </>
  )
};

export default Rating;
