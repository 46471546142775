import React from 'react';
import { Toolbar, SaveButton, NumberInput, SimpleForm, Edit, TextInput } from 'react-admin';
import { NameTitle } from '../../utils/Title.component';

const SaveToolbar = props => (
  <Toolbar {...props} >
    <SaveButton redirect="edit" />
  </Toolbar>
);

const ProfessionalEdit = props => (
  <Edit {...props} title={<NameTitle model="Professional" record={props} />}>
    <SimpleForm toolbar={<SaveToolbar />}>
      <TextInput source="name" />
      <NumberInput source="rating" />
      <TextInput source="avatarUrl" />
      <NumberInput source="interval" />
      <TextInput source="email" />
      <TextInput source="phone" />
    </SimpleForm>
  </Edit>
);

export default ProfessionalEdit