import { formatObject } from './formatObject';

export const change_options_has_upload = (type, resource, params) => {
  if (type === 'CREATE' && resource === 'upload') {
    const formData = new FormData()
    formData.append('image', params.data.image.rawFile)
    return {
      method: 'POST',
      body: formData
    }
  } else {
    return {
      method: 'POST', body: JSON.stringify(formatObject(type, resource, params).data)
    }
  }
}
