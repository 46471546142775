import React, { useState, useEffect } from 'react';
import { Button, BooleanInput, Loading  } from 'react-admin';
import dataProvider from '../../../providers/dataProvider';

export const WhatsappMessagesList = props => {
  if (!props.show) {
    return (
      <Button
        style={{
          padding: 5,
          margin: 5,
          borderRadius: 5,
          backgroundColor: '#3689ea',
          color: '#ffffff',
          fontWeight: 'bold',
          minWidth: 250
        }}
        label="Selecione uma Guia"
      />
    )
  };

  const [firstOpen, setFirstOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [messageList, setMessageList] = useState([]);

  const style = {
    listStyleType: "none",
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    height: '45px',
    flexDirection: 'row',
  }

  useEffect(() => {
    async function fetchAndFilterData() {
      if (firstOpen && props.show) {
        setFirstOpen(false);
        try {
          setLoading(true);
          const params = { page: 1, per_page: 100, id: props.record.id  };
          const { data } = await dataProvider('GET_MANY_REFERENCE', 'business_whatsapp_message', params);
          const onlyBusiness = data.filter(el => { return el.whatsappMessage.name.slice(0, 8) === 'business' });

          setMessageList(onlyBusiness);
          setLoading(false);
        } catch (err) {
          setLoading(false);
        }
      };
    };
    fetchAndFilterData();
  }, [props.show]);

  const save = async (changedItem) => {
    try {
      const params = {};
      params.data = changedItem;
      await dataProvider('UPDATE', 'business_whatsapp_message', params);
    } catch(err) {
      alert('Não foi possível salvar, tente novamente.', JSON.stringify(err))
    }
  }

  const change = (id) => {
    setLoading(true);
    let changedItem = {};
    const newList = messageList.map(el => { 
      if (el.id === id) {
        changedItem = { ...el, able: !el.able? 'true': 'false' };
        return { ...el, able: !el.able };
      } else {
        return el;
      }
    })
    setMessageList(newList);
    save(changedItem);
    setLoading(false);
  }

  return (
    <>
      {(loading &&
        <Loading/>
      )}
      {(!loading &&
        <ul>
          {messageList && messageList[0] && messageList.map(el => {
            return (
              <li style={style}>
                <BooleanInput defaultValue={el.able} label={el.whatsappMessage.name} source={el.id} value={el.able} onClick={() => change(el.id)} />
              </li>
            )
          })}
        </ul>
      )}
    </>
  )
};

export default WhatsappMessagesList;