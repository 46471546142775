import React from 'react';
import { Pagination, ReferenceManyField, Datagrid, TextField } from 'react-admin';
import { Button } from "react-admin";
import { Link } from 'react-router-dom';

const AddFeatureButton = ({ record }) => {
  return (
    <Button
      component={Link}
      to={`/feature/create?category_id=${record.id}`}
      label="Add Feature"
      title="New Feature"
    />
  )
}

const FeatureList = props => (
  <React.Fragment>
    <ReferenceManyField
      {...props}
      addLabel={false}
      reference="feature"
      target="category_id"
      sort={{ field: 'name', order: 'ASC' }}
      pagination={<Pagination />}
    >
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <TextField source="category.name" sortable={false} />
        <TextField source="level" />
      </Datagrid>
    </ReferenceManyField>
    <AddFeatureButton {...props} />
  </React.Fragment>

);

export default FeatureList;
