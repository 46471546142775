export const styles = {
  row: {
    backgroundColor: '#eeeeee',
    backgroundPosition: 'center',
    height: '20px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 'normal',
    color: '#oooooo',
    padding: 8,
  },
  headerRow: {
    backgroundColor: '#3689ea',
    backgroundPosition: 'center',
    height: '20px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 'normal',
    color: '#ffffff',
    padding: 8,
  },
  header: {
    backgroundColor: '#3689ea',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '20px',
    width: '40%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'normal',
    fontSize: 20,
    color: '#oooooo',
    padding: 8,
  },
  headerGrade: {
    backgroundColor: '#3689ea',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '20px',
    width: '10%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'normal',
    fontSize: 20,
    color: '#oooooo',
    padding: 8,
  },
  headerComment: {
    backgroundColor: '#3689ea',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '20px',
    width: '40%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'normal',
    fontSize: 20,
    color: '#oooooo',
    padding: 8,
  },
  line: {
    backgroundColor: '#eeeeee',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '20px',
    width: '40%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'normal',
    fontSize:  15,
    color: '#oooooo',
    padding: 8,
    paddingLeft: 50,
  },
  lineGrade: {
    backgroundColor: '#eeeeee',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '20px',
    width: '10%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'normal',
    fontSize:  15,
    color: '#oooooo',
    padding: 8,
  },
  lineComment: {
    backgroundColor: '#eeeeee',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '20px',
    width: '40%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'normal',
    fontSize:  15,
    color: '#oooooo',
    padding: 8,
    maxWidth: 800,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  Container: {
    padding: '20px',
    width: '95%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'center',
  },
  TextContainer: {
    borderRadius: '5px',
    backgroundColor: '#474747',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: '30px 30px',
    width: '50%',
    height: '50px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
  },
  WithoutTransactionsMessage: {
    fontWeight: 'bold',
    color: '#ffffff',
    fontSize: '30px',
  },
  button: {
    padding: 5,
    margin: 5,
    borderRadius: 5,
    backgroundColor: '#3689ea',
    color: '#ffffff',
    fontWeight: 'bold',
    minWidth: 250,
  },
  pageRow: {
    backgroundPosition: 'center',
    height: '20px',
    width: '70%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 'normal',
    color: '#oooooo',
    padding: 8,
  },
};

export default styles;
