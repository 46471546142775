import React from 'react';
import { Toolbar, SaveButton, TextInput, SimpleForm, Edit } from 'react-admin';

const SaveToolbar = props => (
  <Toolbar {...props} >
    <SaveButton redirect="edit" />
  </Toolbar>
);

const PhotoEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<SaveToolbar />}>
      <TextInput source="url" />
      <TextInput source="thumbnailUrl" />
    </SimpleForm>
  </Edit>
);

export default PhotoEdit;