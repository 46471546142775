import React from 'react';
import { Pagination, BooleanField, ReferenceManyField, Datagrid, TextField } from 'react-admin';
import { Button } from "react-admin";
import { Link } from 'react-router-dom';

const AddCategoryButton = (props) => (
  <Button
    component={Link}
    to={`/category/create?sector_id=${props.id}`}
    label="Add category"
    title="New Category"
  />
);

const CategoryList = props => (
  <React.Fragment>
    <ReferenceManyField
      {...props}
      addLabel={false}
      reference="category"
      target="sector_id"
      sort={{ field: 'name', order: 'ASC' }}
      pagination={<Pagination />}
    >
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <TextField source="color" />
        <BooleanField source="allowSelectMoreFeature" />
        <TextField source="placeType" />
        <TextField source="sector.name" />
      </Datagrid>
    </ReferenceManyField>
    <AddCategoryButton {...props} />
  </React.Fragment>

);

export default CategoryList;