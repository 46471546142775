import React, { useState, useEffect } from 'react';
import dataProvider from '../../../providers/dataProvider';
import styles from './Styles';
import { Loading, Button} from 'react-admin';

const Transactions = props => {

  const [dataPayments, setDataPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const businessId = props.record.id;

  let show = props.show ? true : false;

  const getData = async () => {
    const params = { businessId: businessId, status: 'partial', page: page }
    
    try {
      const { data } = await dataProvider('GET_ONE', 'api/v1/payment', params);
      setDataPayments(data);
      setLoading(false);
    } catch (err) {
      console.log('Erro get data.', JSON.stringify(err));
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!!show) {
      setLoading(true);
      getData();
    }
  }, [page]);

  if (!show) {
    return (
      <Button
        style={ styles.button2}
        label="Selecione uma Guia"
      />
    )
  };

  const dataToString = (date) => {
    return (`${date.slice(0, 10)} às ${date.slice(11, 16)}`);
  }
  
  const dataToMoney = (value) => {

    return (`R$ ${value/100}`)
  }
  
  return (
    <div style={styles.Container}>
      <div>
        {loading && <Loading />}
        {!loading && (!dataPayments || dataPayments.length === 0) && 
          <>          
            <div style={styles.TextContainer}>
              <div style={styles.WithoutTransactionsMessage}>
                {`Sem Transações para exibir :(`}
              </div>
            </div>
          </>  
        }
      </div>
      {dataPayments && dataPayments.items_list &&
        <div>
        {dataPayments.items_list.map((transaction) => {
          const {
            payment_id,
            transaction_date,
            payment_type,
            payment_status,
            transaction_value,
            discount_value,
            refunded_value,
            available_value,
            available,
          } = transaction.payment;
          const clientName = (transaction.bookingTransaction && transaction.bookingTransaction.bookings[0]
            && transaction.bookingTransaction.bookings[0].client && transaction.bookingTransaction.bookings[0].client.name);
          return (
            <div key={payment_id} style={styles.card}>
              <div style={styles.row}>
                <div>Cliente: {clientName}</div>
              </div>
              <div style={styles.rowData}>
                <div style={styles.column}>
                  <div>Data Transação: {dataToString(transaction_date)}</div>
                  <div>Forma de pagamento: {payment_type}</div>
                  <div>Status: {payment_status} </div>
                  <div>Disponível em: {dataToString(available)}</div>
                </div>
                <div style={styles.column}>
                  <div>Valor Total: {dataToMoney(transaction_value)}</div>
                  <div>Desconto: {dataToMoney(discount_value)}</div>
                  <div>Reembolso: {dataToMoney(refunded_value)}</div>
                  <div>Disponível: {dataToMoney(available_value)}</div>
                </div>
              </div>
            </div>
          )
        })}
        </div>
      }
      {dataPayments && dataPayments.items_list &&
        <>
          <div style={{
            marginLeft: '60%',
            color: '#3689ea',
            fontWeight: 'bold',
            alignItems: 'end',
          }}>Página {page} de { dataPayments.total_pages }</div>
          <div style={styles.rowButton}>
            <Button
              style={page===1? styles.buttonDisabled : styles.button}
              label="Primeira"
              disabled={page===1}
              onClick={() => setPage(1)}
            />
            <Button
              style={page===1? styles.buttonDisabled : styles.button}
              disabled={page===1}
              label="<-"
              onClick={() => setPage(page - 1)}
            />
            <Button
              style={dataPayments.total_pages === page? styles.buttonDisabled : styles.button}
            disabled={dataPayments.total_pages === page}
              label="->"
              onClick={() => { setPage(page + 1) }}
            />
            <Button
              style={dataPayments.total_pages === page? styles.buttonDisabled : styles.button}
              disabled={dataPayments.total_pages === page}
              label="Última"
              onClick={() => { setPage(dataPayments.total_pages) }}
            />
          </div>
        </>
      }
    </div>
  )
};

export default Transactions;
