import React, { Component } from 'react';

import {
  MuiThemeProvider,
} from '@material-ui/core/styles';

import {
  Card,
  CardActions,
  Button,
  FormControl,
  TextField,
} from '@material-ui/core';

import firebase from '../providers/firebaseConfig';

const styles = {
  main: {
    backgroundColor: '#2196f3',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    maxWidth: 360,
  },
  form: {
    padding: '0 1em 1em 1em',
  },
  input: {
    display: 'flex',
  },
  hint: {
    textAlign: 'center',
    marginTop: '1em',
    color: '#ccc',
  },
  backLogin: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  backLoginLink: {
    color: '#4D5473',
    fontSize: '12px',
  },
  botaoRecovery: {
    fontWeight: 'bold',
    color: '#26384E',
  },
};

class UserRecovery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
    };
  }


  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  recoveryLogin = (e) => {
    e.preventDefault();
    const { username } = this.state;
    firebase.auth().sendPasswordResetEmail(username).then(function() {
      alert('Recuperação de senha enviado para o email.')
    }).catch(function(error) {
      alert('Erro ao enviar email de confirmação.')
    });
  }

  render() {
    const {
      submitting,
    } = this.props;

    const {
      username,
    } = this.state;
    return (
      <MuiThemeProvider>
        <div style={styles.main}>
          <Card style={styles.card}>
            <form onSubmit={this.recoveryLogin}>
              <div style={styles.form}>
                <FormControl fullWidth={true}>
                  <TextField
                    id="username"
                    name="username"
                    type="text"
                    label="Username"
                    onChange={this.handleChange}
                    value={username}
                    margin="normal"
                    required={true}
                  />
                </FormControl>
              </div>
              <CardActions>
                <Button
                  variant="contained"
                  type="submit"
                  primary
                  disabled={submitting}
                  fullWidth
                  style={styles.botaoRecovery}
                >
                  Enviar email de recuperação
                </Button>
              </CardActions>
              <CardActions style={styles.backLogin}>
                  <a style={styles.backLoginLink} href="/#/login">Voltar ao login</a>
              </CardActions>
            </form>
          </Card>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default UserRecovery;
