import React from 'react';
import { Filter, TextInput, ReferenceField, List, Datagrid, TextField } from 'react-admin';

const FilterByName = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
  </Filter>
);

const ServiceList = props => (
  <List
    {...props}
    filters={<FilterByName />}
    sort={{ field: 'name', order: 'ASC' }}
    bulkActionButtons={false}
  >
    <Datagrid rowClick="edit">
    <TextField source="name" />
      <ReferenceField label="Pricing Type" source="pricingTypeId" reference="pricing_type" allowEmpty>
        <TextField source="name" />
      </ReferenceField>
      <TextField source="nameEng" />
      <TextField source="namePort" />
      <TextField source="price" />
      <TextField source="duration" />
    </Datagrid>
  </List>
);

export default ServiceList;