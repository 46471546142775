import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';
import { fetchUtils } from 'react-admin';
import { getUserTokenId } from '../providers/firebaseConfig';

import firebase from '../providers/firebaseConfig';
import Roles from '../utils/const/roles';

const TOKEN = 'token';
const ROLE = 'role';
const API_URL = process.env.REACT_APP_API_URL;

export default async (type, params) => {
  if (type === AUTH_LOGIN) {
    try {
      await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      await firebase.auth().signInWithEmailAndPassword(params.username, params.password);
      const currentUser = await firebase.auth().currentUser;

      if (currentUser) {
        const token = await currentUser.getIdToken();
        const options = {
          method: 'GET'
        };
        options.headers = new Headers({ Accept: 'application/json' });
        options.headers.set('Authorization', `${token}`);
        const dataMe = await fetchUtils.fetchJson(`${API_URL}/me`, options);
        const dataRole = await fetchUtils.fetchJson(`${API_URL}/role/${dataMe.json.roleId}`, options);
        if(dataRole.json.name === Roles.ADMIN || dataRole.json.name === Roles.SUPERADMIN) {
          localStorage.setItem(TOKEN, token);
          localStorage.setItem(ROLE, dataRole.json.name);
          return Promise.resolve();
        }
        return Promise.reject();
      } else {
        return Promise.reject();
      }

    } catch (error) {
      console.error(error);
      return Promise.reject();
    }
  }
  if (type === AUTH_LOGOUT) {
    firebase.auth().signOut();
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(ROLE);
    return Promise.resolve();
  }

  if (type === AUTH_ERROR) {
    const status = params.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem(TOKEN);
      localStorage.removeItem(ROLE);
      return Promise.reject();
    }
    return Promise.resolve();
  }
  if (type === AUTH_CHECK) {
    return getUserTokenId() ? Promise.resolve() : Promise.reject();
  }
  if (type === AUTH_GET_PERMISSIONS) {
    const role = localStorage.getItem('role');
    return role ? Promise.resolve(role) : Promise.reject();
  }
  return Promise.resolve();
}
