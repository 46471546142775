import React from 'react';
import { ReferenceInput, SelectInput, Toolbar, SaveButton, SimpleForm, Edit, NumberInput } from 'react-admin';

const SaveToolbar = props => (
  <Toolbar {...props} >
    <SaveButton redirect="edit" />
  </Toolbar>
);

const BusinessServiceEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<SaveToolbar />}>
      <NumberInput source="duration" />
      <NumberInput source="price" />
      <ReferenceInput label="Pricing Type" source="pricingTypeId" reference="pricing_type">
          <SelectInput optionText="name" />
        </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export default BusinessServiceEdit;