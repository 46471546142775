import React from 'react';
import { Filter, TextInput, List, Datagrid, TextField } from 'react-admin';

const FilterByName = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
  </Filter>
);

const BusinessTypeList = props => (
  <List
    {...props}
    filters={<FilterByName />}
    sort={{ field: 'name', order: 'ASC' }}
    bulkActionButtons={false}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" />
    </Datagrid>
  </List>
);

export default BusinessTypeList;