import React from 'react';

import { Admin, Resource } from 'react-admin';

import SignIn from './login/signin';
import SignOutButton from './login/signoutButton';

import sector from './components/sector';
import category from './components/sector/category';
import feature from './components/sector/feature';
import featureItem from './components/sector/featureItem';
import availableFeature from './components/sector/availableFeature';

import businessType from './components/businessType';
import business from './components/business';
import professional from './components/business/professional';
import whatsappMessages from './components/business/whatsappMessages';
import photo from './components/business/photo';
import client from './components/business/client';
import businessService from './components/business/businessService';
import service from './components/service';
import upload from './components/upload';
import subscription from './components/business/subscription'

import Layout from './layout/Layout';
import dataProvider from './providers/dataProvider';
import authProvider from './providers/authProvider';
import customRoutes from './providers/routeProvider';

const App = () => (
  <Admin
    appLayout={Layout}
    loginPage={SignIn}
    logoutButton={SignOutButton}
    dataProvider={dataProvider}
    authProvider={authProvider}
    customRoutes={customRoutes}
  >
    <Resource name="sector" {...sector} />
    <Resource name="category" {...category} />
    <Resource name="feature" {...feature} />
    <Resource name="feature_item" {...featureItem} />
    <Resource name="available_feature" {...availableFeature} />
    <Resource name="business_type" {...businessType} />
    <Resource name="business" {...business} />
    <Resource name="professional" {...professional} />
    <Resource name="client" {...client} />
    <Resource name="photo" {...photo} />
    <Resource name="business_service" {...businessService} />
    <Resource name="service" {...service} />
    <Resource name="pricing_type" />
    <Resource name="upload" {...upload} />
    <Resource name="user" />
    <Resource name="subscription" {...subscription} />
    <Resource name="whatsappMessages" {...whatsappMessages} />
  </Admin>
);

export default App;