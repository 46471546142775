import React from 'react';
import { ImageInput, ImageField, SimpleForm, Create } from 'react-admin';

const UploadCreate = props => (
  <Create {...props} redirect="list">
    <SimpleForm>
      <ImageInput source="image" label="image" accept="image/*">
        <ImageField source="image" title="title" />
      </ImageInput>
    </SimpleForm>
  </Create>
);

export default UploadCreate;