import React, { useState, useEffect } from 'react';
import dataProvider from '../../../providers/dataProvider';
import styles from './Styles';
import { Loading, DateInput, Button} from 'react-admin';

const SalesHistory = props => {
  const [utcStartDate, setUtcStartDate] = useState();
  const [utcEndDate, setUtcEndDate] = useState();
  const [dataBookings, setDataBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const businessId = props.record.id;

  let show = props.show ? true : false;
  useEffect(() => {
    const today = new Date();
    const aux = new Date();
    const endDay = new Date(aux.setDate(aux.getDate() + 30));

    setUtcStartDate(today);
    setUtcEndDate(endDay);
  }, [show]);

  const dateToString = (date) => {
    if (!date) { return false };
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString();
    const day = date.getDate().toString();

    return year + '/' + month + '/' + day;
  };

  const getData = async () => {
    const startDate = dateToString(utcStartDate);
    const endDate = dateToString(utcEndDate);
    const params = { id: businessId, startDate: startDate, endDate: endDate }
    
    if (!startDate) { return false }
    try {
      const { data } = await dataProvider('GET_ONE', 'api/v1/payment/list/grouped', params);
      setDataBookings(data);
      setLoading(false);
    } catch (err) {
      console.log('Erro get data.', JSON.stringify(err));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!!show) {
      setLoading(true);
      getData();
    }
  }, [utcStartDate, utcEndDate]);

  if (!show) {
    return (
        <Button
          style={ styles.button}
          label="Selecione uma Guia"
        />
    )
  };

  const changeDate = (date, start) => {
    const startYear = date[0] + date[1] + date[2] + date[3];
    const startMonth = parseInt(date[5] + date[6])-1;
    const startDay = date[8] + date[9];

    if (start) {
      setUtcStartDate(new Date(startYear, startMonth, startDay));
    } else {
      setUtcEndDate(new Date(startYear, startMonth, startDay));
    }
  };

  return (
    <div style={styles.Container}>
      <div>
        <div style={styles.date}>
          <DateInput source='startTime' label="First Day" defaultValue={ utcStartDate } onChange={ (value)=>{changeDate(value, true)}}/>
          <DateInput source='endTime' label="Last Day" defaultValue={ utcEndDate } onChange={(value) => { changeDate(value, false) }} />
        </div>
        {loading && <Loading />}
        {!loading && (!dataBookings || dataBookings.length === 0) && 
          <>          
            <div style={styles.TextContainer}>
              <div style={styles.WithoutTransactionsMessage}>
                Sem Transações para exibir
              </div>
            </div>
          </>  
        }
      </div>
      {dataBookings && 
        <div>
        {dataBookings.map((data, index) => {
        return (
          <div key={ index }>
            <div style={styles.paymentType}>Pagamentos em {data && data.payment_type}:</div>
            {data.booking_transactions.map((bookingTransactions, index) => {
              return(
                <div key={ index } style={ styles.card}>
                  {bookingTransactions.bookings.map((el, index) => {
                    const startDate = new Date(el.startTime);
                    const startDateString = startDate.getDate() + '/' + (startDate.getMonth() +1) + '/' + startDate.getFullYear();
                    const startHour = startDate.getHours() + ':' + (startDate.getMinutes().length === 2? startDate.getMinutes() : '0'+ startDate.getMinutes() );
                    return (
                      <div key={ index }>
                        <div style={styles.row}>
                          <div>{startDateString} às {startHour}</div>
                          <div>Status: {(el.status === 'active' || el.status === 'completed' || el.status === 'accept')? 'PAGO' : el.status} </div>
                          <div>Duração: {el.duration} Minutos </div>
                        </div>
                        <div style={styles.row}>
                          <div>Cliente: {el.client.name}</div>
                          <div>Tel: {el.client.phone}</div>
                        </div>
                        <div style={styles.row}>
                          <div>Serviço: {el.service.name}</div>
                          <div>Preço: {el.price}</div>
                          <div>Profissional: {el.professional.name}</div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
        )
        })}
        </div>
      }
    </div>
  )
};

export default SalesHistory;
