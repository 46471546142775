import React from 'react';
import { connect } from 'react-redux';
import { userLogout } from 'react-admin';
import Button from '@material-ui/core/Button';
import ExitIcon from '@material-ui/icons/PowerSettingsNew';

const SignOutButton = ({ userLogout, ...rest }) => (
  <Button
    onClick={userLogout}
    {...rest}
  >
    <ExitIcon />
    Logout
  </Button>
);
const redirectTo = '/';
const customLogout = () => userLogout(redirectTo);
export default connect(undefined, { userLogout: customLogout })(SignOutButton);