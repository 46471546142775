import { UPDATE } from 'react-admin';

const BUSINESS = 'business';

export const formatObject = function (type, resource, params) {
  params = formatId(type, resource, params);
  params = formatBusiness(type, resource, params);
  return params;
}

const formatBusiness = function (type, resource, params) {
  if (resource === BUSINESS && type === UPDATE) {
    for (var social in params.data.social) {
      const name_id = 'social_'.concat(social);
      params.data[name_id] = params.data.social[social];
    }
    for (var address in params.data.address) {
      const name_id = 'address_'.concat(address);
      params.data[name_id] = params.data.address[address];
    }
  }
  return params;
}

const formatId = function (type, resource, params) {
  if (type === UPDATE) {
    for (var property in params.data) {
      if (params.data[property] !== null &&
        Object.keys(params.data[property]).length &&
        params.data[property].hasOwnProperty('id')) {
        const name_id = property.concat('_id');
        params.data[name_id] = params.data[property].id;
      }
    }
  }
  return params;
}