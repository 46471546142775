import React from 'react';
import { ReferenceField, Pagination, ReferenceManyField, Datagrid, TextField, Button } from 'react-admin';

export const BusinessServiceList = props => {
  if (!props.show) {
    return (
      <Button
        style={{
          padding: 5,
          margin: 5,
          borderRadius: 5,
          backgroundColor: '#3689ea',
          color: '#ffffff',
          fontWeight: 'bold',
          minWidth: 250
        }}
        label="Selecione uma Guia"
      />
    )
  };
  
 return (
  <ReferenceManyField
    {...props}
    addLabel={false}
    reference="business_service"
    target="business_id"
    pagination={<Pagination />}
  >
    <Datagrid rowClick="show">
      <TextField source="service.name" />
      <TextField source="duration" />
      <TextField source="price" />
      <ReferenceField label="Pricing Type" source="pricingTypeId" reference="pricing_type" allowEmpty>
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
   </ReferenceManyField>
 )
};

export default BusinessServiceList;