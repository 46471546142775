import React from 'react';
import { Pagination, ReferenceManyField, Datagrid, TextField, Button } from 'react-admin';

const ClientList = props => {
  if (!props.show) {
    return (
      <Button
        style={{
          padding: 5,
          margin: 5,
          borderRadius: 5,
          backgroundColor: '#3689ea',
          color: '#ffffff',
          fontWeight: 'bold',
          minWidth: 250
        }}
        label="Selecione uma Guia"
      />
    )
  };

 return (

    <ReferenceManyField
    {...props}
    addLabel={false}
    reference="client"
    target="business_id"
    sort={{ field: 'name', order: 'ASC' }}
    pagination={<Pagination />}
  >
    <Datagrid rowClick="show">
      <TextField source="name" />
      <TextField source="phone" />
    </Datagrid>
   </ReferenceManyField>
 )
};

export default ClientList;