
export const styles = {

  menu: {
    backgroundPosition: 'center',
    padding: 10,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'space-between',
    justifyContent: 'center',
  },
    
  activeButton: {
    padding: 5,
    margin: 5,
    borderRadius: 5,
    backgroundColor: '#3689ea',
    color: '#ffffff',
    fontWeight: 'bold',
    minWidth: 250,
  },
  
  disabledButton: {
    padding: 5,
    margin: 5,
    borderRadius: 5,
    backgroundColor: '#dddddd',
    color: '#3689ea',
    fontWeight: 'bold',
    minWidth: 250,
  },
    
  Container: {
    padding: '20px',
    width: '95%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  
};

export default styles;
