import React from 'react';
import { Filter, TextInput, ReferenceField, List, Datagrid, TextField, downloadCSV } from 'react-admin';

import dataProvider from '../../providers/dataProvider';
import * as jsonExport from 'jsonexport/dist';

const BusinessList = (props) => {

  const FilterByName = (props) => (
    <Filter {...props}>
      <TextInput label="Search" source="name" alwaysOn />
    </Filter>
  );

  const exporter = async () => {
    try {
      const allBusiness = await dataProvider('GET_LIST', 'business/report_invited_clients', '');

      setTimeout(() => {
        exportData();
      }, 1000);

      const exportData = () => {
        jsonExport(allBusiness.data, {
          headers: []
        }, (err, csv) => {
          downloadCSV(csv, 'businesses');
        });
      };
    } catch (error) {
      console.error(error);
      return Promise.reject();
    }
  };

  return (

    <List
      {...props}
      filters={<FilterByName />}
      sort={{ field: 'name', order: 'ASC' }}
      bulkActionButtons={false}
      exporter={exporter}
    >
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <ReferenceField label="Owner" source="ownerId" reference="user" allowEmpty>
          <TextField source="name" />
        </ReferenceField>
        <TextField source="createdAt" />
      </Datagrid>
    </List>

  )
};

export default BusinessList;
