import React from 'react';
import {
  Toolbar,
  SaveButton,
  DeleteButton,
  SelectInput,
  SimpleForm,
  Edit,
  NumberInput,
  ReferenceInput,
} from 'react-admin';
import { required } from 'ra-core';

import QueryCategory from './QueryCategory';

const SaveToolbar = props => (
  <Toolbar {...props} >
    <SaveButton
      redirect={(basePath, id, data) => `/feature_item/${data.featureItemId}/1`}
    />
    <DeleteButton
      redirect={(basePath, id, data) => {
        const { record } = props;
        return `/feature_item/${record.featureItemId}/1`;
      }}
    />
  </Toolbar>
);

const AvailableFeatureEdit = props => (
  <Edit {...props}>
    <SimpleForm
      toolbar={<SaveToolbar />}
    >
      <ReferenceInput
        source="featureItemId"
        reference="feature_item"
        disabled
        validate={required()}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>

      <QueryCategory>
        {(categoryId) => (
          <ReferenceInput
            source="featureId"
            reference="feature"
            filter={{ category_id: categoryId }}
            sort={{ field: 'name', order: 'ASC' }}
            validate={required()}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        )}
      </QueryCategory>

      <NumberInput source="level" validate={required()} />
    </SimpleForm>
  </Edit>
);

export default AvailableFeatureEdit;