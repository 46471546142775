import React from 'react';
import { NumberInput, ReferenceInput, BooleanInput, SelectInput, SimpleForm, Create, TextInput } from 'react-admin';
import { required } from 'ra-core';

const FeatureItemCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <NumberInput source="precedence" validate={required()} />
      <BooleanInput source="firstBooking" />
      <ReferenceInput source="feature_id" reference="feature" validate={required()}>
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create >
);

export default FeatureItemCreate;