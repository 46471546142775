import { toSnakeCase } from './snakecase'

export const search_filter = function (params) {
  let query = '';
  if (params.filter && Object.keys(params.filter).length) {
    for (var key in params.filter) {
      if (params.filter.hasOwnProperty(key)) {
        query += `&${key}=${params.filter[key]}`
      }
    }
  }
  return query;
}

export const pagination_filter = function (params) {
  if (params.pagination && Object.keys(params.pagination).length && (params.pagination.perPage && params.pagination.page)) {
    return `per_page=${params.pagination.perPage}&page=${params.pagination.page}&`;
  }
  return '';
}

export const sort_filter = function (params) {
  if (params.sort && Object.keys(params.sort).length && (params.sort.field && params.sort.order)) {
    return `field=${toSnakeCase(params.sort.field)}&order=${params.sort.order}`;
  }
  return '';
}
