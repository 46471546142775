import React from 'react';
import {
  Pagination,
  ReferenceManyField,
  ReferenceField,
  Datagrid,
  TextField,
} from 'react-admin';
import { Button } from "react-admin";
import { Link } from 'react-router-dom';

const AddAvailableFeatureButton = ({ record }) => {
  return (
    <Button
      component={Link}
      to={`/available_feature/create?feature_item_id=${record.id}`}
      label="Add Available Feature"
      title="New Available Feature"
    />
  )
}

const AvailableFeatureList = props => (
  <React.Fragment>
    <ReferenceManyField
      {...props}
      addLabel={false}
      reference="available_feature"
      target="feature_item_id"
      sort={{ field: 'level', order: 'ASC' }}
      pagination={<Pagination />}
    >
      <Datagrid rowClick="edit">
        <ReferenceField label="Feature" source="featureId" reference="feature" allowEmpty>
          <TextField source="name" />
        </ReferenceField>
        <TextField source="level" />
      </Datagrid>
    </ReferenceManyField>
    <AddAvailableFeatureButton {...props} />

  </React.Fragment>
);

export default AvailableFeatureList;
