import React from 'react';
import { Toolbar, SaveButton, SimpleForm, Edit, BooleanInput } from 'react-admin';

const SaveToolbar = props => (
  <Toolbar {...props} >
    <SaveButton redirect="edit" />
  </Toolbar>
);

const WhatsappMessagesEdit = props => {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<SaveToolbar />}>
        <BooleanInput label="Able?" source="able" />
      </SimpleForm>
    </Edit>
  );
}

export default WhatsappMessagesEdit;