import React from 'react';
import { SimpleForm, Edit, TextInput } from 'react-admin';
import { NameTitle } from '../utils/Title.component';

const BusinessTypeEdit = props => (
  <Edit {...props} title={<NameTitle model="Business Type" record={props} />}>
    <SimpleForm>
      <TextInput source="name" />
    </SimpleForm>
  </Edit>
);

export default BusinessTypeEdit;