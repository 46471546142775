import React from 'react';
import {
  Query,
} from 'react-admin';
import { parse } from "query-string";

const QueryCategory = props => {
  let featureItemId;

  const { record, location } = props;

  if (location) {
    ({ feature_item_id: featureItemId } = parse(props.location.search));
  }

  if (record) {
    ({ featureItemId } = record);
  }

  return featureItemId && (
    <Query type="GET_ONE" resource="feature_item" payload={{ id: featureItemId }}>
      {({ data: featureItemData, loading, error }) => (
        !loading && !error && (
          <Query type="GET_ONE" resource="feature" payload={{ id: featureItemData.feature.id }}>
            {({ data, loading, error }) => !loading && !error && props.children(data.category.id)}
          </Query>
        )
      )}
    </Query>
  );
}

export default QueryCategory;
