import React from 'react';
import { connect } from 'react-redux';
import { WithPermissions, MenuItemLink, getResources } from 'react-admin';
import { withRouter } from 'react-router-dom';

import sector from '../components/sector';
import businessType from '../components/businessType';
import business from '../components/business';
import upload from '../components/upload';
import service from '../components/service';
import Roles from '../utils/const/roles';

const menuTree = [
  sector,
  businessType,
  business,
  service,
  upload,
]

const Menu = ({ onMenuClick }) => (

  <WithPermissions
    render={({ permissions }) => (
      permissions === Roles.ADMIN
        ?
        <>
          <div>
            {menuTree.map((subMenu) => {
              return (
                <MenuItemLink
                  key={subMenu.name}
                  to={`/${subMenu.name}`}
                  primaryText={subMenu.name}
                  onClick={onMenuClick}
                />
              );
            })}
          </div>
        </>
        : null
    )}
  />
);

const mapStateToProps = state => ({
  resources: getResources(state),
});

export default withRouter(connect(mapStateToProps)(Menu));