import CategoryEdit from './CategoryEdit';
import CategoryList from './CategoryList';
import CategoryCreate from './CategoryCreate';

export default {
  name: 'category',
  edit: CategoryEdit,
  list: CategoryList,
  create: CategoryCreate,
};
