import AvailableFeatureList from './AvailableFeatureList';
import AvailableFeatureCreate from './AvailableFeatureCreate';
import AvailableFeatureEdit from './AvailableFeatureEdit';

export default {
  name: 'available_feature',
  list: AvailableFeatureList,
  edit: AvailableFeatureEdit,
  create: AvailableFeatureCreate,
};
