import React from 'react';
import { AutocompleteInput, required, LongTextInput, ReferenceInput, NumberInput, SelectInput, SimpleForm, Create, TextInput } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import _ from 'lodash';

const BusinessCreate = (props) => {

  const {
    sector,
    form,
  } = props;

  const setCategories = (sectorId) => {
    const categories = _.map(sector).find(sec => sec.id === sectorId).categories.map(cat => cat.id)
    form.values['category_id'] = categories
  }

  const namePhoneRenderer = value => `${value.name} : ${value.phone}`;

  return (
    <Create {...props}>
      <SimpleForm>
        <Grid container >
          <Grid item xs={2} sm={3}>
            <TextInput source="name" validate={required()} />
          </Grid>
          <Grid item xs={2} sm={3}>
            <TextInput source="thumbnail_url" />
          </Grid>
        </Grid>

        <ReferenceInput source="ownerId" reference="user" sort={{ field: 'name', order: 'ASC' }}
          allowEmpty={true}
        >
          <AutocompleteInput optionText={namePhoneRenderer} />
        </ReferenceInput>

        <ReferenceInput source="sectorId" reference="sector" validate={required()}
          onChange={(key, value) => setCategories(value)}>
          <SelectInput optionText="name" />
        </ReferenceInput>

        <LongTextInput source="description" />
        <NumberInput source="price_range" />
        <NumberInput source="rating" />

        <Grid container >
          <Grid item xs={2} sm={3}>
            <SelectInput source="status" validate={required()} choices={[
              { id: 'active', name: 'active' },
              { id: 'inactive', name: 'inactive' },
              { id: 'manual', name: 'manual' }
            ]} />
          </Grid>
          <Grid item xs={2} sm={3}>
            <SelectInput source="schedulingType" validate={required()} choices={[
              { id: 'chat', name: 'chat' },
              { id: 'schedule', name: 'schedule' },
              { id: 'schedule_chat', name: 'schedule_chat' }
            ]} />
          </Grid>
          <Grid item xs={2} sm={3}>
            <SelectInput source="serviceType" validate={required()} choices={[
              { id: 'household', name: 'household' },
              { id: 'business', name: 'business' }
            ]} />
          </Grid>
        </Grid>

        <TextInput source="phone" />
        <TextInput source="website" />

        <Grid container >
          <Grid item xs={3} sm={3}>
            <TextInput source="social_facebook" />
          </Grid>
          <Grid item xs={3} sm={3}>
            <TextInput source="social_twitter" />
          </Grid>
          <Grid item xs={3} sm={3}>
            <TextInput source="social_instagram" />
          </Grid>
        </Grid>

        <Grid container >
          <Grid item xs={2} sm={3}>
            <NumberInput source="latitude" />
          </Grid>
          <Grid item xs={2} sm={3}>
            <NumberInput source="longitude" />
          </Grid>
        </Grid>

        <Grid container >
          <Grid item xs={2} sm={3}>
            <TextInput source="address_street" />
          </Grid>
          <Grid item xs={2} sm={3}>
            <TextInput source="address_neighborhood" />
          </Grid>
        </Grid>

        <Grid container >
          <Grid item xs={2} sm={3}>
            <TextInput source="address_number" />
          </Grid>
          <Grid item xs={2} sm={3}>
            <TextInput source="address_unit" />
          </Grid>
          <Grid item xs={2} sm={3}>
            <TextInput source="address_postal_code" />
          </Grid>
        </Grid>

        <Grid container >
          <Grid item xs={2} sm={3}>
            <TextInput source="address_city" />
          </Grid>
          <Grid item xs={2} sm={3}>
            <TextInput source="address_state" />
          </Grid>
          <Grid item xs={2} sm={3}>
            <TextInput source="address_country" />
          </Grid>
        </Grid>

        <ReferenceInput source="businessTypeId" reference="business_type">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="time_zone" validate={required()} />
      </SimpleForm>
    </Create>
  )
};

const mapStateToProps = ({
  admin,
  form,
}) => ({
  sector: admin.resources.sector.data,
  form: form['record-form'],
});

export default connect(mapStateToProps, null)(BusinessCreate);
