import React from 'react';
import { BooleanInput, Toolbar, SaveButton, TabbedForm, FormTab, Edit, TextInput } from 'react-admin';
import { required } from 'ra-core';
import FeatureList from '../feature/FeatureList';
import { NameTitle } from '../../utils/Title.component';

const SaveToolbar = props => (
  <Toolbar {...props} >
    <SaveButton redirect="edit" />
  </Toolbar>
);

const CategoryEdit = props => (
  <React.Fragment>
    <Edit {...props} title={<NameTitle model="Category" record={props} />}>
      <TabbedForm toolbar={<SaveToolbar />}>
        <FormTab label="Category" redirect="list">
          <TextInput source="name" validate={required()} />
          <TextInput source="image" />
          <TextInput source="color" />
          <BooleanInput source="allowSelectMoreFeature" />
          <TextInput source="nomenclature" label="Nomenclatura" />
          <TextInput source="placeType" label="Place Type" />
        </FormTab>
        <FormTab label="Feature">
          <FeatureList />
        </FormTab>
      </TabbedForm>
    </Edit>
  </React.Fragment>
);
export default CategoryEdit;




