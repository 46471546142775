
export const styles = {
  
  yearContainer: {
    padding: '10px 10px 0 0px',
    margin: '10px 10px 0 0px',
    width: '80%',
  },
  
  yearTitle: {
    borderRadius: '10px 10px 0 0',
    backgroundColor: '#3689ea',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: 10,
    width: '100%',
    height: '20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'space-between',
    justifyContent: 'space-between',
    fontWeight: 'bold',
    color: '#ffffff',
    margin: 0,
  },
  
  row: {
    backgroundColor: '#eeeeee',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '20px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'space-between',
    justifyContent: 'space-between',
    fontWeight: 'normal',
    color: '#oooooo',
    padding: 10,

  },

  monthContainer: {
    padding: '0 10px 10px 0px',
    margin: '0 15px 0px 0px',
    width: '100%',
    alignItems: 'center',

  },

  monthTitle: {
    borderRadius: '5px',
    backgroundColor: '#474747',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: 10,
    width: '100%',
    height: '20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'space-between',
    justifyContent: 'space-between',
    fontWeight: 'bold',
    color: '#ffffff',
    margin: 0,
  },
  
  dayContainer: {
    padding: '0 10px 10px 10px',
    margin: '0 0px 0px 10px',
    width: '95%',
    alignItems: 'center',

  },
    
  dayTitle: {
    borderRadius: '5px',
    backgroundColor: '#BBBBCB',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: '10px 20px',
    width: '100%',
    height: '20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'space-between',
    justifyContent: 'space-between',
    fontWeight: 'bold',
    color: '#000000',
    margin: 0,
  },
    
  hr: {
    margin: 3
  },
  
  WithoutTransactionsMessage: {
    fontWeight: 'bold',
    color: '#ffffff',
    fontSize: '30px',
    
  },
  
  TextContainer: {
    borderRadius: '5px',
    backgroundColor: '#474747',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: '30px 30px',
    width: '50%',
    height: '50px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
  },
  
    Container: {
    padding: '20px',
    width: '95%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export default styles;
