import React from 'react';
import { NumberField, Pagination, BooleanField, ReferenceManyField, Datagrid, TextField } from 'react-admin';
import { Button } from "react-admin";
import { Link } from 'react-router-dom';

const AddFeatureItemButton = ({ record }) => {
  return (
    <Button
      component={Link}
      to={`/feature_item/create?feature_id=${record.id}`}
      label="Add Feature Item"
      title="New Feature Item"
    />
  )
}

const FeatureItemList = props => (
  <React.Fragment>
    <ReferenceManyField
      {...props}
      addLabel={false}
      reference="feature_item"
      target="feature_id"
      sort={{ field: 'name', order: 'ASC' }}
      pagination={<Pagination />}
    >
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <NumberField source="precedence" />
        <BooleanField source="firstBooking" />
        <TextField source="feature.name" sortable={false} />
      </Datagrid>
    </ReferenceManyField>
    <AddFeatureItemButton {...props} />

  </React.Fragment>
);

export default FeatureItemList;
