import firebase from 'firebase/app';
import 'firebase/auth';

var firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "prit-app-dev.firebaseapp.com",
  projectId: "prit-app-dev"
};

firebase.initializeApp(firebaseConfig);

const firebaseLoaded = new Promise(resolve => {
  firebase.auth().onAuthStateChanged(resolve)
})

export const getUserTokenId = async () => {
  await firebaseLoaded;
  const user = await firebase.auth().currentUser;
  if (user) {
    return await user.getIdToken();
  }
  return null;
}

export default firebase;