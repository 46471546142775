import React, { useState } from 'react';
import styles from './Select.styles';
import { Button } from 'react-admin';
import ExtractShow from './ExtractShow';
import FutureShow from './FutureShow';

const Select = props => {
  if (!props.show) {
    return (
      <Button
        style={{
          padding: 5,
          margin: 5,
          borderRadius: 5,
          backgroundColor: '#3689ea',
          color: '#ffffff',
          fontWeight: 'bold',
          minWidth: 250
        }}
        label="Selecione uma Guia"
      />
    )
  };

  const [extractVisible, setExtractVisible] = useState(true);
  const showExtract = () => {
    setExtractVisible(true);
  };
  const showFuture = () => {
    setExtractVisible(false);
  };
  return (
    <div style={styles.Container}>
      <div style={styles.menu}>
        <Button
          style={ extractVisible? styles.activeButton : styles.disabledButton }
          onClick={()=>showExtract()}
          label="Extrato"
        />
        <Button
          style={ extractVisible? styles.disabledButton : styles.activeButton }
          onClick={()=>showFuture()}
          label="Lançamentos Futuros"
        />
      </div>
      {(extractVisible &&
        <>
          <ExtractShow props={props} />
        </>
      )}
      {(!extractVisible &&
        <>
          <FutureShow props={props} />
        </>
      )}
    </div>
  )
};

export default Select;
