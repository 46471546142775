import React from 'react';
import { List, TextField, ImageField, Datagrid } from 'react-admin';

const UploadList = props => (
  <List {...props}>
    <Datagrid>
      <TextField source="url" />
      <ImageField source="url" />
    </Datagrid>
  </List>
);

export default UploadList