import React, { useState, useEffect } from 'react';
import { ReferenceManyField, TextField, Datagrid, fetchUtils, Button } from 'react-admin';

import firebase from '../../../providers/firebaseConfig';

const SubscriptionStatus = props => {

  if (!props.show) {
    return (
      <Button
        style={{
          padding: 5,
          margin: 5,
          borderRadius: 5,
          backgroundColor: '#3689ea',
          color: '#ffffff',
          fontWeight: 'bold',
          minWidth: 250
        }}
        label="Selecione uma Guia"
      />
    )
  };

  const API_PAYOUT_URL = process.env.REACT_APP_API_PAYOUT_URL;
  const [pritPayStatus, setPritPayStatus] = useState('loading...');

  useEffect(() => {
    getPritPayStatus();
  }, []);

  const getPritPayStatus = async () => {

    const { id } = props.record;
    const currentUser = await firebase.auth().currentUser;
    const token = await currentUser.getIdToken();
    const options = {
      method: 'GET'
    };
    options.headers = new Headers({ Accept: 'application/json' });
    options.headers.set('Authorization', `${token}`);
    const response = await fetchUtils.fetchJson(`${API_PAYOUT_URL}/api/v1/recipient/contract/${id}`, options);

    if (response.status === 204) {
      setPritPayStatus("Inativo");
    }
    else if (response.status === 200) {
      const bankAccount = await fetchUtils.fetchJson(`${API_PAYOUT_URL}/api/v1/recipient/bank-account/${id}`, options);
      if (bankAccount.status === 204) {
        setPritPayStatus("Dados bancários pendentes");
      }
      else if (bankAccount.status === 200) {
        setPritPayStatus("Ativo");
      }
      else { setPritPayStatus("Erro ao verificar dados bancários"); };
    }
    else { setPritPayStatus("Erro ao verificar contrato"); }
  };

  return (

    <ReferenceManyField
      {...props}
      reference="subscription"
      target="business_id"
    >
      <Datagrid rowClick="edit">
        <TextField source="status" />
        <span label="Status Prit Pay">{pritPayStatus}</span>
      </Datagrid>

    </ReferenceManyField>
  );
}

export default SubscriptionStatus;