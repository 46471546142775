import React from 'react';
import { ArrayInput, SimpleFormIterator, BooleanInput, Toolbar, SaveButton, TabbedForm, FormTab, Edit, TextInput } from 'react-admin';
import { required } from 'ra-core';
import CategoryList from './category/CategoryList';
import { NameTitle } from '../utils/Title.component';

const SaveToolbar = props => (
  <Toolbar {...props} >
    <SaveButton redirect="edit" />
  </Toolbar>
);

const SectorEdit = (props) => (
  <React.Fragment>
    <Edit {...props} title={<NameTitle model="Sector" record={props} />} >
      <TabbedForm toolbar={<SaveToolbar />}>
        <FormTab label="Sector">
          <TextInput source="name" validate={required()} />
          <TextInput source="color" />
          <TextInput source="image" />
          <ArrayInput source="colorGradient">
            <SimpleFormIterator>
              <TextInput />
            </SimpleFormIterator>
          </ArrayInput>
          <BooleanInput source="allowMultipleServices" />
          <BooleanInput source="allowMultipleCategories" />
        </FormTab>
        <FormTab label="Category">
          <CategoryList {...props} />
        </FormTab>
      </TabbedForm>
    </Edit>
  </React.Fragment>
);

export default SectorEdit;