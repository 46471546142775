import React from 'react';
import { ReferenceInput, SelectInput, NumberInput, SimpleForm, Create, TextInput } from 'react-admin';
import { required } from 'ra-core';

const FeatureCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <NumberInput source="level" validate={required()} />
      <ReferenceInput source="category_id" reference="category" validate={required()}>
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export default FeatureCreate;