import React from 'react';
import { ReferenceInput, BooleanInput, SelectInput, SimpleForm, Create, TextInput } from 'react-admin';
import { required } from 'ra-core';

const CategoryCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <TextInput source="image" />
      <TextInput source="color" />
      <BooleanInput source="allowSelectMoreFeature" />
      <TextInput source="nomenclature" label="Nomenclatura" />
      <TextInput source="placeType" label="Place Type" />

      <ReferenceInput source="sector_id" reference="sector" validate={required()}>
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export default CategoryCreate;