import React, { useState, useEffect } from 'react';
import { AutocompleteInput, required, LongTextInput, Toolbar, SaveButton, TabbedForm, FormTab, ReferenceInput, NumberInput, SelectInput, Edit, TextInput, Button } from 'react-admin';
import ProfessionalList from './professional/ProfessionalList';
import BusinessServiceList from './businessService/BusinessServiceList';
import WhatsappMessagesList from './whatsappMessages/WhatsappMessagesList';
import Charge from './charge/Charge';
import Rating from './rating/Rating';
import PhotoList from './photo/PhotoList';
import ClientList from './client/ClientList';
import SubscriptionStatus from './subscription/SubscriptionStatus';
import Select from './extract/Select.js';
import SalesHistory from './salesHistory/SalesHistory.js';
import Transactions from './transactions/Transactions';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { NameTitle } from '../utils/Title.component';

const SaveToolbar = props => (
  <Toolbar {...props} >
    <SaveButton redirect="edit" />
  </Toolbar>
);

const BusinessEdit = (props) => {

  const {
    sector,
    form,
  } = props;

  const [showSectors, setShowSectors] = useState(false);
  const [showTypeId, setShowTypeId] = useState(false);
  const [selectedTab, setSelectedTab] = useState(1);
  const [allCategories] = useState([]);
  const [firstOpen, setFirstOpen] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [selectedArray] = useState([]);

  const refreshAllCategories = () => {
    const sectorIds = Object.keys(sector);
    if (firstOpen && sector && sectorIds[0] && sector[sectorIds[0]].categories && form && form.initial) {
      setFirstOpen(false);
      Object.keys(sector).forEach(function (sectorId) {
        Object.keys(sector[sectorId].categories).forEach(function (categoryIndex) {
          allCategories.push({
            sectorId: sectorId,
            sectorName: sector[sectorId].name,
            categoryId: sector[sectorId].categories[categoryIndex].id,
            categoryName: sector[sectorId].categories[categoryIndex].name,
            selected: form.initial.categories.some(el => el.id === sector[sectorId].categories[categoryIndex].id),
            show: form.initial.sectorId === sectorId,
          });
        });
      });
      form.initial.categories.map((category) => {
        return selectedArray.push(category.id);
      });
    }
  };

  useEffect(() => {
    refreshAllCategories();
  }, [sector]);

  const categoriesBySector = (sectorId) => {
    allCategories.map(item => {
      return item.show = item.sectorId === sectorId;
    });
  };

  const SelectedList = () => {
    if (allCategories && allCategories[0] && allCategories[0].categoryName) {
      return allCategories.sort((a, b) => {
        const x = `${a.sectorName} + ${a.categoryName}`;
        const y = `${b.sectorName} + ${b.categoryName}`;
        return x > y ? 1 : -1;
      }).map((el) => {
        if (el.selected) {
          return (
            <>
              <br />
              <Button style={{
                fontWeight: 'bold',
                minWidth: 350
              }} label={`${el.sectorName} - ${el.categoryName}`} onClick={() => { changeSelectedList(el.categoryId, el.selected) }}>
              </Button>
            </>
          )
        }
        return null;
      });
    } else {
      return (
        <Button label='Carregando...' style={{ marginLeft: 80 }} onClick={() => { }} />
      );
    }
  };

  const changeSelectedList = (categoryId, selected) => {
    allCategories.sort((a, b) => a.categoryName - b.categoryName).map(el => {
      if (el.categoryId === categoryId) {
        el.selected = !selected;
        if (el.selected) {
          selectedArray.push(el.categoryId)
        } else {
          const index = selectedArray.indexOf(el.categoryId);
          if (index > -1) {
            selectedArray.splice(index, 1);
          }
        }
      };
      return null;
    });
    form.values['category_id'] = selectedArray;
    setRefresh(!refresh);
  }

  const CategoryList = () => {
    if (allCategories && allCategories[0] && allCategories[0].categoryName) {
      return allCategories.map((el) => {
        if (el.show && el.selected) {
          return (
            <>
              <br />
              <Button style={{ backgroundColor: '#f0f0f0' }} label={`${el.categoryName}`} onClick={() => { changeSelectedList(el.categoryId, el.selected) }} />
            </>
          )
        } else if (el.show && !el.selected) {
          return (
            <>
              <br />
              <Button label={`${el.categoryName}`} onClick={() => { changeSelectedList(el.categoryId, el.selected) }} />
            </>
          )
        } else {
          return null;
        }
      });
    } else {
      return (
        <Button label="Carregando..." onClick={() => { }} />
      );
    }
  };

  const namePhoneRenderer = value => `${value.name} : ${value.phone}`;

  const tabStyle = { minWidth: 50, marginLeft: -10, marginRight: -10 };
  return (
    <Edit {...props} title={<NameTitle model="Business" record={props} />}>
      <TabbedForm toolbar={<SaveToolbar />}>
        <FormTab label="Business" style={tabStyle} redirect="list">
          <Grid container >
            <Grid item xs={2} sm={3}>
              <TextInput source="name" label="Nome" validate={required()} />
            </Grid>
            <Grid item xs={2} sm={3}>
              <TextInput source="thumbnail_url" label="url da miniatura" disabled />
            </Grid>
          </Grid>

          <ReferenceInput source="ownerId" reference="user" label="Owner" sort={{ field: 'name', order: 'ASC' }}
            allowEmpty={true}
          >
            <AutocompleteInput optionText={namePhoneRenderer} />
          </ReferenceInput>

          {!showSectors && <Button
            style={{
              padding: 5,
              margin: '15px 5px 0 0',
              borderRadius: 5,
              backgroundColor: '#3689ea',
              color: '#ffffff',
              fontWeight: 'bold',
              minWidth: 250
            }}
            label="Carregar Setor e Categorias"
            onClick={() => { setShowSectors(true) }}
          />}
          {showSectors &&
            <>
              <Grid container>
                <Grid item xs={2} sm={3}>
                  <ReferenceInput source="sectorId" reference="sector" label="Setor" validate={required()}
                    onChange={(key, value) => categoriesBySector(value)}>
                    <SelectInput optionText="name" />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={2} sm={3}>
                  <br />
                  <div>Clique para Selecionar</div>
                  <CategoryList />
                </Grid>
                <Grid item xs={2} sm={3}>
                  <br />
                  <div style={{ textAlign: 'center', marginLeft: 80 }}>Categorias Selecionadas</div>
                  <SelectedList />
                </Grid>
              </Grid>
            </>
          }
          <LongTextInput source="description" label="Descrição" />
          <Grid container>
            <Grid item xs={2} sm={3}>
              <NumberInput source="price_range" label="Faixa de Preço" />
            </Grid>
            <Grid item xs={2} sm={3}>
              <NumberInput source="rating" label="Avaliação" />
            </Grid>
          </Grid>
          <Grid container >
            <Grid item xs={2} sm={3}>
              <SelectInput source="status" validate={required()} choices={[
                { id: 'active', name: 'active' },
                { id: 'inactive', name: 'inactive' },
                { id: 'manual', name: 'manual' }
              ]} />
            </Grid>
            {/* <Grid item xs={2} sm={3}>
              <SelectInput source="schedulingType" validate={required()} choices={[
                { id: 'chat', name: 'chat' },
                { id: 'schedule', name: 'schedule' },
                { id: 'schedule_chat', name: 'schedule_chat' }
              ]} />
            </Grid> */}
            <Grid item xs={2} sm={3}>
              <SelectInput source="serviceType" label="Tipo de serviço" validate={required()} choices={[
                { id: 'household', name: 'household' },
                { id: 'business', name: 'business' }
              ]} />
            </Grid>
          </Grid>

          <TextInput source="phone" label="Telefone" />
          <Grid container >
            <Grid item xs={3} sm={3}>
              <TextInput source="social.desktop" label="Site Atual" disabled />
            </Grid>
            <Grid item xs={3} sm={3}>

              <TextInput source="website" label="Site" />
            </Grid>
          </Grid>
          <Grid container >
            <Grid item xs={3} sm={3}>
              <TextInput source="social.facebook" label="Facebook" />
            </Grid>
            <Grid item xs={3} sm={3}>
              <TextInput source="social.twitter" label="Twitter" />
            </Grid>
            <Grid item xs={3} sm={3}>
              <TextInput source="social.instagram" label="Instagram" />
            </Grid>
          </Grid>

          <Grid container >
            <Grid item xs={2} sm={3}>
              <NumberInput source="latitude" disabled />
            </Grid>
            <Grid item xs={2} sm={3}>
              <NumberInput source="longitude" disabled />
            </Grid>
          </Grid>

          <Grid container >
            <Grid item xs={2} sm={3}>
              <TextInput source="address.street" label="Logradouro" />
            </Grid>
            <Grid item xs={2} sm={3}>
              <TextInput source="address.neighborhood" label="Bairro" />
            </Grid>
          </Grid>

          <Grid container >
            <Grid item xs={2} sm={3}>
              <TextInput source="address.number" label="Número" />
            </Grid>
            <Grid item xs={2} sm={3}>
              <TextInput source="address.unit" label="Complemento" />
            </Grid>
            <Grid item xs={2} sm={3}>
              <TextInput source="address.postalCode" label="CEP" />
            </Grid>
          </Grid>

          <Grid container >
            <Grid item xs={2} sm={3}>
              <TextInput source="address.city" label="Cidade" />
            </Grid>
            <Grid item xs={2} sm={3}>
              <TextInput source="address.state" label="Estado" />
            </Grid>
            <Grid item xs={2} sm={3}>
              <TextInput source="address.country" label="País" disabled />
            </Grid>
          </Grid>

          {!showTypeId && <Button
            style={{
              padding: 5,
              margin: '15px 5px 0 0',
              borderRadius: 5,
              backgroundColor: '#3689ea',
              color: '#ffffff',
              fontWeight: 'bold',
              minWidth: 250
            }}
            label="Carregar o Type Id"
            onClick={() => { setShowTypeId(true) }}
          />}
          {showTypeId && <ReferenceInput source="businessType.id" reference="business_type">
            <SelectInput optionText="name" />
          </ReferenceInput>}

          <TextInput source="timeZone" validate={required()} disabled />

        </FormTab>
        <FormTab label="Professional" style={{ minWidth: 50, mmarginLeft: -10, marginRight: -10 }} onClick={() => { setSelectedTab('Professional') }}>
          <ProfessionalList show={selectedTab === 'Professional'} />
        </FormTab>
        <FormTab label="Service" style={tabStyle} onClick={() => { setSelectedTab('BusinessService') }}>
          <BusinessServiceList  {...props} show={selectedTab === 'BusinessService'} />
        </FormTab>
        <FormTab label="Photo" style={tabStyle} onClick={() => { setSelectedTab('Photo') }}>
          <PhotoList show={selectedTab === 'Photo'} />
        </FormTab>
        <FormTab label="Client" style={tabStyle} onClick={() => { setSelectedTab('Client') }}>
          <ClientList show={selectedTab === 'Client'} />
        </FormTab>
        <FormTab label="Subs" style={tabStyle} onClick={() => { setSelectedTab('Subscription') }}>
          <SubscriptionStatus show={selectedTab === 'Subscription'} />
        </FormTab>
        <FormTab label="Transactions" style={tabStyle} onClick={() => { setSelectedTab('Transactions') }}>
          <Transactions show={selectedTab === 'Transactions'} />
        </FormTab>
        <FormTab label="Sales History" style={{ minWidth: 50, marginLeft: -10, marginRight: -10 }} onClick={() => { setSelectedTab('SalesHistory') }}>
          <SalesHistory show={selectedTab === 'SalesHistory'} />
        </FormTab>
        <FormTab label="Extract" style={tabStyle} onClick={() => { setSelectedTab('Extract') }}>
          <Select show={selectedTab === 'Extract'} />
        </FormTab>
        <FormTab label="Whatsapp" style={tabStyle} onClick={() => { setSelectedTab('WhatsappMessages') }}>
          <WhatsappMessagesList  {...props} show={selectedTab === 'WhatsappMessages'} />
        </FormTab>
        <FormTab label="Rating" style={tabStyle} onClick={() => { setSelectedTab('Rating') }}>
          <Rating show={selectedTab === 'Rating'} />
        </FormTab>
        <FormTab label="Charge" style={tabStyle} onClick={() => { setSelectedTab('Charge') }}>
          <Charge show={selectedTab === 'Charge'} />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
};

const mapStateToProps = ({
  admin,
  form,
}) => ({
  sector: admin.resources.sector.data,
  form: form['record-form'],
});

export default connect(mapStateToProps, null)(BusinessEdit);