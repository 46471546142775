import React from 'react';
import {
  ReferenceInput,
  SelectInput,
  SimpleForm,
  Create,
  NumberInput,
} from 'react-admin';
import { required } from 'ra-core';

import QueryCategory from './QueryCategory';

const AvailableFeatureCreate = props => (
  <QueryCategory {...props}>
    {(categoryId) => (
      <Create {...props}>
        <SimpleForm
          redirect={(basePath, id, data) => `/feature_item/${data.feature_item_id}/1`}
        >
          <ReferenceInput
            source="feature_item_id"
            reference="feature_item"
            disabled
            validate={required()}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <ReferenceInput
            source="feature_id"
            reference="feature"
            filter={{ category_id: categoryId }}
            sort={{ field: 'name', order: 'ASC' }}
            validate={required()}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <NumberInput source="level" validate={required()} />
        </SimpleForm>
      </Create>
    )}
  </QueryCategory>
);

export default AvailableFeatureCreate;