import FeatureItemEdit from './FeatureItemEdit';
import FeatureItemList from './FeatureItemList';
import FeatureItemCreate from './FeatureItemCreate';

export default {
  name: 'feature_item',
  edit: FeatureItemEdit,
  list: FeatureItemList,
  create: FeatureItemCreate,
};
