import BusinessEdit from './BusinessEdit';
import BusinessList from './BusinessList';
import BusinessCreate from './BusinessCreate';

export default {
  name: 'business',
  list: BusinessList,
  create: BusinessCreate,
  edit: BusinessEdit,
};
