import React from 'react';
import { ReferenceInput, SelectInput, NumberInput, Toolbar, SaveButton, TabbedForm, FormTab, Edit, TextInput } from 'react-admin';
import { required } from 'ra-core';
import { NameTitle } from '../utils/Title.component';

const SaveToolbar = props => (
  <Toolbar {...props} >
    <SaveButton redirect="edit" />
  </Toolbar>
);

const ServiceEdit = props => {
  return(
    <Edit {...props} title={<NameTitle model="Services" record={props} />}>
    <TabbedForm toolbar={<SaveToolbar />}>
      <FormTab label="Services">
      <TextInput source="name" validate={required()} />
      <TextInput source="nameEng"/>
      <TextInput source="namePort"/>
        <TextInput source="image" />
        <NumberInput source="price" />
        <NumberInput source="duration" />
        <ReferenceInput label="Pricing Type" source="pricingTypeId" reference="pricing_type">
          <SelectInput optionText="name" />
        </ReferenceInput>
      </FormTab>
    </TabbedForm>
  </Edit>
)};

export default ServiceEdit;