import React, { Component } from 'react';
import { propTypes, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import {
  MuiThemeProvider,
  createMuiTheme
} from '@material-ui/core/styles';

import {
  Card,
  CardActions,
  Button,
  FormControl,
  TextField,
} from '@material-ui/core';

import { Notification, userLogin as userLoginAction } from 'react-admin';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  }
});

const styles = {
  main: {
    backgroundColor: '#2196f3',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    maxWidth: 360,
  },
  form: {
    padding: '0 1em 1em 1em',
  },
  input: {
    display: 'flex',
  },
  hint: {
    textAlign: 'center',
    marginTop: '1em',
    color: '#ccc',
  },
  esqueceuSenha: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  esqueceuSenhaLink: {
    color: '#4D5473',
    fontSize: '12px',
  },
  botaoLogin: {
    fontWeight: 'bold',
    color: '#26384E',
  },
};

class SignIn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
    };
  }


  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  login = (e) => {
    e.preventDefault();
    const { userLogin, location } = this.props;
    const { username, password } = this.state;
    userLogin({ username, password }, location.state ? location.state.nextPathname : '/');
  }

  render() {
    const {
      submitting,
    } = this.props;

    const {
      username,
      password,
    } = this.state;
    return (
      <MuiThemeProvider theme={theme}>
        <div style={styles.main}>
          <Card style={styles.card}>
            <form onSubmit={this.login}>
              <div style={styles.form}>
                <FormControl fullWidth={true}>
                  <TextField
                    id="username"
                    name="username"
                    type="text"
                    label="Username"
                    onChange={this.handleChange}
                    value={username}
                    margin="normal"
                    required={true}
                  />
                </FormControl>
                <FormControl fullWidth={true}>
                  <TextField
                    id="password"
                    name="password"
                    type="password"
                    label="Senha"
                    onChange={this.handleChange}
                    value={password}
                    margin="normal"
                    required={true}
                  />
                </FormControl>
              </div>
              <CardActions>
                <Button
                  variant="contained"
                  type="submit"
                  primary
                  disabled={submitting}
                  fullWidth
                  style={styles.botaoLogin}
                >
                  Login
                </Button>
              </CardActions>
              <CardActions style={styles.esqueceuSenha}>
                <a style={styles.esqueceuSenhaLink} href="/#/login/recovery">Esqueci Senha</a>
              </CardActions>
            </form>
          </Card>
          <Notification />
        </div>
      </MuiThemeProvider>
    );
  }
}

SignIn.propTypes = {
  ...propTypes,
};

const enhance = compose(
  reduxForm({
    form: 'signIn',
    validate: (values) => {
      const errors = {};
      if (!values.username) errors.username = 'Preencha este campo.';
      if (!values.password) errors.password = 'Preencha este campo.';
      return errors;
    },
  }),
  connect(null, { userLogin: userLoginAction }),
);

export default enhance(SignIn);
