import React from 'react';
import { ReferenceField, Pagination, ReferenceManyField, EditButton, Datagrid, TextField, Button } from 'react-admin';

const PhotoList = props => {
  if (!props.show) {
    return (
      <Button
        style={{
          padding: 5,
          margin: 5,
          borderRadius: 5,
          backgroundColor: '#3689ea',
          color: '#ffffff',
          fontWeight: 'bold',
          minWidth: 250
        }}
        label="Selecione uma Guia"
      />
    )
  };

  return (
    <ReferenceManyField
      {...props}
      addLabel={false}
      reference="photo"
      target="business_id"
      sort={{ field: 'url', order: 'ASC' }}
      pagination={<Pagination />}
    >
      <Datagrid rowClick="show">
        <ReferenceField label="Business" source="businessId" reference="business" allowEmpty>
          <TextField source="name" />
        </ReferenceField>
        <TextField source="url" />
        <TextField source="thumbnailUrl" />
        <EditButton />
      </Datagrid>
    </ReferenceManyField>
  );
}
export default PhotoList;



