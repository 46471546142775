import React from 'react';
import { Toolbar, SaveButton, TabbedForm, FormTab, Edit, TextInput, NumberInput } from 'react-admin';
import { required } from 'ra-core';
import FeatureItemList from './../featureItem/FeatureItemList';
import { NameTitle } from '../../utils/Title.component';

const SaveToolbar = props => (
  <Toolbar {...props} >
    <SaveButton redirect="edit" />
  </Toolbar>
);

const FeatureEdit = props => (
  <Edit {...props} title={<NameTitle model="Feature" record={props} />}>
    <TabbedForm toolbar={<SaveToolbar />}>
      <FormTab label="Feature" redirect="list">
        <TextInput source="name" validate={required()} />
        <NumberInput source="level" validate={required()} />
      </FormTab>
      <FormTab label="Feature Item">
        <FeatureItemList />
      </FormTab>
    </TabbedForm>
  </Edit>
);

export default FeatureEdit;
