
export const styles = {
  
  column: {
    backgroundColor: '#eeeeee',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '20px',
    width: '95%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-between',
    justifyContent: 'space-between',
    fontWeight: 'normal',
    color: '#oooooo',
    padding: 8,
  },
  
  row: {
    backgroundColor: '#eeeeee',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100%',
    width: '95%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'space-between',
    justifyContent: 'space-between',
    fontWeight: 'normal',
    color: '#oooooo',
    padding: 8,
  },
  
  rowButton: {
    backgroundColor: '#fff',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '40px',
    width: '75%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'normal',
    color: '#oooooo',
    padding: 8,
  },
  rowData: {
    backgroundColor: '#eeeeee',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100px',
    width: '95%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'space-between',
    justifyContent: 'space-between',
    fontWeight: 'normal',
    color: '#oooooo',
    padding: 8,
  },
  
  date: {
    backgroundPosition: 'center',
    height: '20px',
    width: '60%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'space-between',
    justifyContent: 'space-between',
    fontWeight: 'normal',
    color: '#oooooo',
    padding: 8,
    margin: '0 50px 50px 50px',

  },
  
  card: {
    padding: '10px 5px',
    margin: '10px 15px',
    borderRadius: '10px',
    width: '75%',
    backgroundColor: '#eeeeee',
    flexDirection: 'row',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  
  paymentType: {
    fontSize: '24px',
    borderRadius: '10px',
    backgroundColor: '#3689ea',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: 10,
    width: '77%',
    height: '35px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'space-between',
    justifyContent: 'space-between',
    fontWeight: 'bold',
    color: '#ffffff',
    margin: 0,
  },

  Container: {
    padding: '20px',
    width: '95%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'center',
  },

  TextContainer: {
    borderRadius: '5px',
    backgroundColor: '#474747',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: '30px 30px',
    width: '50%',
    height: '50px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
  },
    
  WithoutTransactionsMessage: {
    fontWeight: 'bold',
    color: '#ffffff',
    fontSize: '30px',
  },
  
  button2: {
    padding: 5,
    margin: 5,
    borderRadius: 5,
    backgroundColor: '#3689ea',
    color: '#ffffff',
    fontWeight: 'bold',
    minWidth: 250,
  },
  
  button: {
    padding: 5,
    margin: 5,
    borderRadius: 5,
    backgroundColor: '#3689ea',
    color: '#ffffff',
    fontWeight: 'bold',
    minWidth: 100,
  },
  
  buttonDisabled: {
    padding: 5,
    margin: 5,
    borderRadius: 5,
    backgroundColor: '#eee',
    color: '#ffffff',
    fontWeight: 'bold',
    minWidth: 100,
  },
};

export default styles;
