import React from 'react';
import { Pagination, ReferenceManyField, Datagrid, TextField, Button } from 'react-admin';

const ProfessionalList = props => {
  if (!props.show) {
  return (
    <Button
    style={{
      padding: 5,
      margin: 5,
      borderRadius: 5,
      backgroundColor: '#3689ea',
      color: '#ffffff',
      fontWeight: 'bold',
      minWidth: 250
    }}
      label="Selecione uma Guia"
    />
  )
  };

  return (
    <>
      {props && props.show && <ReferenceManyField
        {...props}
        addLabel={false}
        reference="professional"
        target="business_id"
        sort={{ field: 'name', order: 'ASC' }}
        pagination={<Pagination />}
      >
      <Datagrid rowClick="show">
        <TextField source="name" />
        <TextField source="rating" />
        <TextField source="avatarUrl" />
        <TextField source="interval" />
        <TextField source="email" />
        <TextField source="phone" />
      </Datagrid>
      </ReferenceManyField>
      }
    </>
  )
};

export default ProfessionalList;

