import FeatureEdit from './FeatureEdit';
import FeatureList from './FeatureList';
import FeatureCreate from './FeatureCreate';

export default {
  name: 'feature',
  edit: FeatureEdit,
  list: FeatureList,
  create: FeatureCreate,
};
