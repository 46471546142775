import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  DELETE,
  DELETE_MANY,
  fetchUtils,
} from 'react-admin';
import qs from 'qs';

import { search_filter, pagination_filter, sort_filter } from '../utils/filter';
import { formatObject } from '../utils/formatObject';
import { change_options_has_upload } from '../utils/upload';
import { getUserTokenId } from '../providers/firebaseConfig';

const API_URL = process.env.REACT_APP_API_URL;
const API_PAYOUT_URL = process.env.REACT_APP_API_PAYOUT_URL;

/**
 * @param {String} type One of the constants appearing at the top of this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */

const convertDataProviderRequestToHTTP = (type, resource, params) => {
  params = formatObject(type, resource, params)
  const query = `${pagination_filter(params)}${sort_filter(params)}${search_filter(params)}`

  switch (type) {
    case GET_ONE:
      if (resource.split('/')[2] === 'report' && params.month) {
        return {
          url: `${API_PAYOUT_URL}/${resource}?recipient_id=${params.id}&year=${params.year}&month=${params.month}`,
          options: {
          }
        };
      } else if (resource.split('/')[2] === 'report' && params.year) {
        return {
          url: `${API_PAYOUT_URL}/${resource}?recipient_id=${params.id}&year=${params.year}`,
          options: {
          }
        };
      } else if (resource.split('/')[2] === 'report') {
        return {
          url: `${API_PAYOUT_URL}/${resource}?recipient_id=${params.id}`,
          options: {
          }
        };
      } else if (resource === 'api/v1/payment') {
        return {
          url: `${API_PAYOUT_URL}/${resource}/${params.businessId}?page=${params.page}`,
          options: {
          },
        };
      } else if (resource === 'api/v1/payment/list/grouped') {
        return {
          url: `${API_PAYOUT_URL}/${resource}/${params.id}?startDate=${params.startDate}&endDate=${params.endDate}`,
          options: {
          },
        };
      } else if (resource === "business_whatsapp_message") {
        return {
          url: `${API_URL}/business_whatsapp_message/messages/${params.id}`,
          options: {
          },
        };
      } else if (resource === 'rating') {
        return {
          url: `${API_URL}/${resource}?${params.businessId ? `business_id=${params.businessId}&` : ''}page=${params.page}&per_page=${params.per_page}`,
          options: {
          },
        };
      } else if (resource === 'changeSubscription') {
        return {
          url: `${API_URL}/subscription/${params.id}`,
          options: {
            method: 'PUT',
            body: JSON.stringify(params),
          },
        };
      } else if (resource === 'businessPlan') {
        return {
          url: `${API_URL}/subscription?business_id=${params.id}`,
          options: {
            method: 'GET',
          },
        };
      } else if (resource === 'businessPlanSign') {
        return {
          url: `${API_URL}/subscription/${params.id}?status=pending`,
          options: {
            method: 'PUT',
          },
        };
      } else if (resource === 'subscriptionPlan') {
        const enableFilter = params.business_create_date !== undefined ? `enable=true&business_create_date=${params.business_create_date}&add_levels=true` : 'enable=true';
        return {
          url: `${API_URL}/subscription_plan?${enableFilter}`,
          options: {
            method: 'GET',
          },
        };
      } else if (resource === 'getQrCode') {
        return {
          url: `${API_PAYOUT_URL}/api/v1/payment/pay-subscription`,
          options: {
            method: 'POST',
            body: JSON.stringify(params),
          },
        };
      } else {
        return {
          url: `${API_URL}/${resource}/${params.id}`,
          options: {
          },
        };
      }
    case GET_LIST: {
      if (resource === 'business/report_invited_clients') {
        return {
          url: `${API_URL}/${resource}`,
          options: {
          },
        };
      } else if (resource === 'sector') {
        return {
          url: `${API_URL}/${resource}?${query}&without_category=true`,
          options: {
          },
        };
      } else {
        const decodedQuery = decodeURIComponent(query);
        const modifiedQuery = decodedQuery.includes('ids[]') ? decodedQuery.replace('ids[]', `/category_ids[]`) : decodedQuery;
        return {
          url: `${API_URL}/${resource}?${modifiedQuery}`,
          options: {
          },
        };
      }
    }
    case GET_MANY: {
      return {
        url: `${API_URL}/${resource}?${qs.stringify(params, { arrayFormat: 'brackets' })}`,
        options: {
        },
      };
    }
    case GET_MANY_REFERENCE: {
      var guests = '';
      if (resource === 'client') {
        guests = '&guests=true'
      }
      if (resource === "business_whatsapp_message")
        return {
          url: `${API_URL}/business_whatsapp_message/messages/business/${params.id}`,
          options: {
          },
        };
      return {
        url: `${API_URL}/${resource}?${params.target}=${params.id}&${query}${guests}`,
        options: {
        },
      };
    }
    case UPDATE:
      if (resource === "business_whatsapp_message") {
        let body = params.data;
        body.able = body.able.toString();
        return {
          url: `${API_URL}/business_whatsapp_message/whatsapp_message`,
          options: { method: 'POST', body: JSON.stringify(body) },
        };
      }
      return {
        url: `${API_URL}/${resource}/${params.id}`,
        options: { method: 'PUT', body: JSON.stringify(params.data) },
      };
    case CREATE:
      return {
        url: `${API_URL}/${resource}`,
        options: change_options_has_upload(type, resource, params)
      };
    case DELETE:
      return {
        url: `${API_URL}/${resource}/${params.id}`,
        options: { method: 'DELETE' },
      };
    case DELETE_MANY:
      return {
        url: `${API_URL}/${resource}`,
        options: { method: 'DELETE', body: JSON.stringify(params) },
      };
    default:
      throw new Error(`Unsupported fetch action type ${type}`);
  }
};

/**
 * @param {Object} response HTTP response from fetch()
 * @param {String} type One of the constants appearing at the top of this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} Data Provider response
 */
const convertHTTPResponseToDataProvider = (response, type, resource, params) => {
  const { headers, json } = response;

  switch (type) {
    case GET_LIST:
      if (resource === 'business/report_invited_clients') {
        return {
          data: json.map(x => x),
        };
      } else {
        return {
          data: json.map(x => x),
          total: parseInt(headers.get('X-Total-Count').split('/').pop(), 10),
        };
      }
    case CREATE:
      return { data: { ...params.data, id: json.id } };
    case DELETE:
      return { data: { id: 0 } };
    case GET_MANY_REFERENCE:
      return {
        data: json,
        total: parseInt(headers.get('X-Total-Count').split('/').pop(), 10)
      }
    case DELETE_MANY:
      return {
        data: [json]
      }
    default:
      return { data: json };
  }
};

/**
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for response
 */
export default async (type, resource, params) => {
  const { fetchJson } = fetchUtils;
  const { url, options } = convertDataProviderRequestToHTTP(type, resource, params);
  const token = await getUserTokenId();
  options.headers = new Headers({ Accept: 'application/json' });
  options.headers.set('Authorization', `${token}`);

  return fetchJson(url, options)
    .then(response => convertHTTPResponseToDataProvider(response, type, resource, params));
};
