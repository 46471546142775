import React, { useState, useEffect } from 'react';
import dataProvider from '../../../providers/dataProvider';
import styles from './Styles';
import { Loading } from 'react-admin';

const ExtractShow = ({ props: { record: { id }}}) => {
  const [dataYear, setDataYear] = useState();
  const [dataMonth, setDataMonth] = useState();
  const [dataDaily, setDataDaily] = useState();
  
  const [visibleDay, setVisibleDay] = useState([]);
  const [visibleMonth, setVisibleMonth] = useState([]);
  const [visibleYear, setVisibleYear] = useState([]);
  
  const [loading, setLoading] = useState(true);
  const [loadingMonth, setLoadingMonth] = useState(false);
  const [loadingDay, setLoadingDay] = useState(false);

  const changeVisibleDay = (index) => {
    let visible = visibleDay.slice();
    visible[index] =  !visible[index];
    setVisibleDay(visible);
  }
  
  const changeVisibleMonth = (index, year, month) => {
    let visible = visibleMonth.slice();
    visible[index] =  !visible[index];
    setVisibleMonth(visible);

    let auxIndex = year.toString() + month.month.toString();
    if (visibleMonth && !(dataDaily && dataDaily[auxIndex])) {
      setLoadingDay(true);
      getDailyData(year, month);
    }
  }
  
  const changeVisibleYear = (index, year) => {
    let visible = visibleYear.slice();
    visible[index] =  !visible[index];
    setVisibleYear(visible);
    if (visibleYear && !(dataMonth && dataMonth[year])) {
      setLoadingMonth(true);
      getMonthData(year);
    }
  }
  
  useEffect(() => {
    getYearData();
  }, []);

  const getYearData = async () => {
    const params = {id: id }
    try {
      const responseYear = await dataProvider('GET_ONE', 'api/v1/report/payout/yearly/outcoming', params);
      setDataYear(responseYear.data || null);
      setLoading(false);
    } catch (err) {
      console.log('Erro get year data.', JSON.stringify(err));
      setLoading(false);
    }
  };
  const getMonthData = async (year) => {
    const params = { id: id, year }
    try {
      const responseMonth = await dataProvider('GET_ONE', 'api/v1/report/payout/monthly/outcoming', params);
      let aux = { ...dataMonth };
      aux[year] = responseMonth.data;
      setDataMonth(aux);
      setLoadingMonth(false);
    } catch (err) {
      console.log('Erro get month data.', JSON.stringify(err));
      setLoadingMonth(false);
    }
  }

  const getDailyData = async (year, { month }) => {
    const params = { id: id, year, month }
    try {
      const responseDaily = await dataProvider('GET_ONE', 'api/v1/report/payout/daily/outcoming', params);
      let aux = { ...dataDaily };
      let auxIndex = year.toString() + month.toString();
      aux[auxIndex] = responseDaily.data;
      setDataDaily(aux);
      setLoadingDay(false);

    } catch (err) {
      console.log('Erro get daily data.', JSON.stringify(err));
      setLoadingDay(false);
    }
  }
  
  const getDate = (data) => {
    const fullMonth = (index) => {
      const month = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
      return month[index];
    }

    const newDate = data.reference ? new Date(data.reference) : false;
    const UTCday = newDate? newDate.getUTCDate() : '';
    const UTCmonth = newDate? newDate.getUTCMonth() : data.month || '';
    const UTCyear = newDate? newDate.getUTCFullYear() : data.year;
    const period = (UTCday? `${UTCday} de ${fullMonth(UTCmonth)}` : (UTCmonth || UTCmonth===0 )? `${fullMonth(UTCmonth-1)} ${UTCyear}` : `${UTCyear}`);
        
    return period;
  }

  const Report = ({ data }) => {
    return (
      <>
        <div style={styles.row}>
          <div>
            {`Lançamentos de vendas`}
          </div>
          <div>
            {(data.sales_amount).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
          </div>
        </div>

        <div style={styles.row}>
          <div>
            {`Pagamentos a terceiros`}
          </div>
          <div>
            {(0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
          </div>
        </div>
        
        <div style={styles.row}>
          <div>
            {`Cancelamentos`}
          </div>
          <div>
            {(data.cancelled_value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
          </div>
        </div>

        <div style={styles.row}>
          <div>
            {`Taxas`}
          </div>
          <div>
            {(data.transfer_fee).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
          </div>
        </div>
        <div style={styles.row}>
          <div>
            {`Saque`}
          </div>
          <div>
            {(data.transfer_value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
          </div>
        </div>
        <div style={styles.hr}/>
        <div style={styles.row}>
          <div>
            {`Saldo em ${getDate(data)}`}
          </div>
          <div>
            {(data.avaialable_value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
          </div>
        </div>
      </>
    )
  }
  
  
  const Daily = ({ date }) => {
    let auxIndex = date.year.toString() + date.month.toString();
    return (
      <>
        {(dataDaily && dataDaily[auxIndex] &&  
          dataDaily[auxIndex].map((day, index) => (
            <>
              <div key={auxIndex+index} style={styles.dayContainer}>
                <div style={styles.dayTitle} onClick={()=>changeVisibleDay(auxIndex+index)}>
                  <div>
                    {`Disponível em ${getDate(day)} ${date.year}`}
                  </div>
                  <div>
                    {(day.sales_amount).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                  </div>
                </div>
                {visibleDay[auxIndex+index] &&
                  <Report data={day} />
                }
              </div>
            </>
          ))
         )}
      </>
    )
  }
  
  const Month = ({ year }) => {
    let auxIndex = year.toString();
    return (
      <>
        {(dataMonth && dataMonth[year] &&  
          dataMonth[year].map((month, index) => (
            <>
            {(month.year === year &&
              <div key={auxIndex+index} style={styles.monthContainer}>
                <div style={styles.monthTitle} onClick={()=>changeVisibleMonth(auxIndex+index, year, month)}>                
                <div>
                  {`${getDate(month)}`}
                </div>
                <div>
                  {(month.sales_amount).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                </div>
              </div>
                {visibleMonth[auxIndex+index] &&
                  <>
                    <Report data={month} />
                    <Daily date={month} />
                  </>
                }
                {(loadingDay &&
                  <Loading/>
                )}
              </div>
            )}
          </>
          ))
         )}
      </>
    )
  }
  
  const Year = () => {
    
    return (
      <>
        {(dataYear && dataYear[0] &&
          dataYear.map((year, index) => (
            <div key={index} style={styles.yearContainer}>
              <div style={styles.yearTitle} onClick={()=>changeVisibleYear(index, year.year)}>                
                <div>
                  {`${year.year}`}
                </div>
                <div>
                  {(year.sales_amount).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                </div>
              </div>
              {visibleYear[index] &&
                <>
                <Report data={year} />
                <Month year={year.year} />
                </>
              }
              {(loadingMonth &&
                <Loading/>
              )}
            </div>
          ))
        )}
        {(dataYear === null &&
          <>
            <div style={styles.TextContainer}>
              <div style={styles.WithoutTransactionsMessage}>
                Sem Transações para exibir
              </div>
            </div>
          </>
        )}
      </>
    )
  }
  return (
    <div style={styles.Container}>
      <Year />
      {(loading &&
        <Loading/>
      )}
    </div>  )
};

export default ExtractShow;
