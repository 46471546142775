import React, { useState, useEffect } from 'react';
import { Edit } from 'react-admin';
import dataProvider from '../../../providers/dataProvider';
import { Loading } from 'react-admin';


const status_choices = [
  { id: 0, name: 'testing' },
  { id: 1, name: 'pending' },
  { id: 2, name: 'active' },
  { id: 3, name: 'inactive' }
];

const SubscriptionEdit = props => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');
  const [feedback, setFeedback] = useState(false);
  const [availablePlans, setAvailablePlans] = useState();
  const [selectedPlan, setSelectedPlan] = useState({});
  const [expiration, setExpiration] = useState();
  //const [businessCreateDate, setBusinessCreateDate] = useState();
  const [idPlan, setIdPlan] = useState();

  const getPlans = async () => {
      const params = {
        business_create_date: '2023-07-25T17:43:30.762Z',
        id: props.id,
      };
  
      const { data } = await dataProvider('GET_ONE', 'subscriptionPlan', params);
      const updatedPlans = data.map(plan => ({
        ...plan,
        title: 
          plan.daysRef === 365 ? `Anual - ${plan.title}` :
          plan.daysRef === 30 ? `Mensal - ${plan.title}` :
          plan.title,
      }));
      setAvailablePlans(updatedPlans);
      setSelectedPlan(updatedPlans[0]);
  }

  const changePlan = (index) => {
    const selectedPlan = availablePlans[index];
    setSelectedPlan(selectedPlan);
    const today = new Date();
    const expirationDate = today.getUTCDate() + selectedPlan.daysRef;

    today.setDate(expirationDate);
    setExpiration(today);
  }

  useEffect(() => {
    if (availablePlans && selectedPlan) {
      changePlan(0);
    }
  }, [availablePlans]);

  const getSubscription = async () => {
    try {
      setLoading(true);
      const params = { id: props.id };
      const { data } = await dataProvider('GET_ONE', 'subscription', params);
      setStatus(status_choices.filter(choice => choice.name === data.status));
      setLoading(false);
      setIdPlan(data.subscriptionPlanId)
    } catch (err) {
      console.log('Erro get data', err);
      setLoading(false);
    }
  };

  const valueName = () =>{
    if(availablePlans){
    const mappedArray = availablePlans
    .filter(plan => plan.id === idPlan)
    .map(plan => plan.title);
    return mappedArray.length > 0 ? mappedArray : 'Sem plano assinado.';

  }}

  const changeSubscription = async () => {
    if (status[0].id < 2) {
      alert('Só é possível alterar o status para ACTIVE ou INACTIVE');
    } else {
      const today = new Date();
      try {
        setLoading(true);
        const params = { status: status[0].name, id: props.id, subscriptionPlanId: selectedPlan.id, expiration: status[0].id === 2 ? expiration : today };
        const { data } = await dataProvider('GET_ONE', 'changeSubscription', params);
        setLoading(false);
        if (data && data.status) {
          setFeedback(data.status)
        }
      } catch (err) {
        console.log('Erro get data', err);
        setLoading(false);
      }
    };
  };

  useEffect(() => {
    getSubscription();
    getPlans();
  }, [])

  return (
    <Edit {...props}>
      <div>
        <select
          value={status.name}
          style={{ margin: '50px', fontSize: 16, padding: '5px' }}
          onChange={event => setStatus(status_choices.filter(choice => choice.id === event.target.options.selectedIndex))}
        >
          <option selected={status && status[0].id === 0} value='testing'>testing</option>
          <option selected={status && status[0].id === 1} value='pending'>pending</option>
          <option selected={status && status[0].id === 2} value='active'>active</option>
          <option selected={status && status[0].id === 3} value='inactive'>inactive</option>
        </select>

        <select
          value={selectedPlan.title}
          onChange={event => {
            changePlan(event.target.options.selectedIndex);
          }}
          style={{ marginRight: '50px', marginleft: 0, fontSize: 16, padding: '5px' }}
        >
          {availablePlans && availablePlans.map((plan) => {
            return (<option value={plan.title}>{plan.title}</option>)
          })
          }
        </select>

        <button onClick={() => changeSubscription()} label='Salvar'
          style={{
            padding: 5,
            margin: 5,
            borderRadius: 5,
            backgroundColor: '#3689ea',
            color: '#ffffff',
            fontWeight: 'bold',
            minWidth: 250
          }}>
          Salvar
        </button>
        <div style={{margin: '20px auto',textAlign: 'center'}}>Plano atual: {valueName()}</div>
        {feedback && !loading &&
          <div style={{
            padding: 5,
            margin: 5,
            backgroundColor: '#ff3333',
            color: '#ffffff',
            fontWeight: 'bold',
          }}>{`Status alterado para ${feedback.toUpperCase()}`}</div>
        }
        {(loading &&
          <Loading />
        )}
      </div>
    </Edit>
  )
};

export default SubscriptionEdit;