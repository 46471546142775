import BusinessTypeEdit from './BusinessTypeEdit';
import BusinessTypeList from './BusinessTypeList';
import BusinessTypeCreate from './BusinessTypeCreate';

export default {
  name: 'business_type',
  list: BusinessTypeList,
  create: BusinessTypeCreate,
  edit: BusinessTypeEdit,
};
